import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NativeModules, Platform, ScrollView, View } from 'react-native';
import { Divider, IconButton, Menu } from 'react-native-paper';
import { StackScreenProps } from '@react-navigation/stack';

import styles from './styles';
import { UserStackParamList } from '../../../routes/user';
import { useAppDispatch } from '../../../store';
import ServiceOrderForm, { FormData } from '../components/service-order-form';
import {
  deleteServiceOrder,
  editServiceOrder,
  getServiceOrder,
  sendByEmail,
} from '../../../store/actions/service-order';
import { ServiceOrderData, ServiceOrderWithRelations } from '../../../types/service-order';
import { ConfirmDialog, Dialog, Input, Loading } from '../../../components';
import {
  isConsultantSelector,
  isCoordinatorSelector,
  userSelector,
} from '../../../store/selectors/auth';
import { isValidEmail } from '@brazilian-utils/brazilian-utils';
import { setError } from '../../../store/actions/error';
import { Task } from '../../../types/project';
import { approveServiceOrder, reproveServiceOrder } from '../../../store/actions/coordinator';

type Props = StackScreenProps<UserStackParamList, 'ServiceOrderDetails'>;

export default function ServiceOrderDetails({ route, navigation }: Props) {
  const dispatch = useAppDispatch();
  const isConsultant = useSelector(isConsultantSelector);
  const isCoordinator = useSelector(isCoordinatorSelector);
  const user = useSelector(userSelector);
  const { params } = route;
  const [loadingData, setLoadingData] = useState(false);
  const [loadingApproval, setLoadingApproval] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [showEmailDialog, setShowEmailDialog] = useState(false);
  const [email, setEmail] = useState(user?.email || '');
  const [initialValues, setInitialValues] = useState({} as Partial<FormData>);

  const loadData = async () => {
    setLoadingData(true);
    try {
      const { payload } = await dispatch(getServiceOrder(params.id));
      const data = payload as ServiceOrderData;
      setInitialValues({
        date: data.attributes.date,
        amount_time: data.attributes.amount_time,
        start_time: data.attributes.start_time,
        end_time: data.attributes.end_time,
        break: data.attributes.break,
        transfer: data.attributes.transfer,
        activities: data.attributes.activities.map(activity => {
          return {
            ...activity,
            task: (activity.task as Task)?.id,
            taskDescription: (activity.task as Task)?.description,
          };
        }),
        project: data.attributes.project,
        projectId: data.attributes?.project?.id,
        projectTitle: data.attributes?.project?.title,
        customerId: data.attributes?.customer?.id,
        customerName: data.attributes?.customer?.name,
      });
    } finally {
      setLoadingData(false);
    }
  };

  const sendEmail = () => {
    setShowEmailDialog(false);
    if (!isValidEmail(email)) {
      dispatch(setError('Endereço de e-mail inválido.'));
      return;
    }

    dispatch(sendByEmail({ id: params.id, email }));
  };

  const handleDeleteServiceOrder = () => {
    setShowConfirmAlert(false);
    dispatch(deleteServiceOrder(params.id));
  };

  const handleOnApprove = async (approved: boolean) => {
    setLoadingApproval(true);

    try {
      if (approved) {
        await dispatch(approveServiceOrder(params.id));
      } else {
        await dispatch(reproveServiceOrder(params.id));
      }
    } finally {
      setLoadingApproval(false);
    }
  };

  const onSubmit = async (data: FormData) => {
    setLoading(true);
    const serviceOrder: ServiceOrderWithRelations = {
      ...data,
      amount_time: data.amount_time,
      start_time: data.start_time,
      end_time: data.end_time,
      break: data.break || '00:00:00',
      transfer: data.transfer || '00:00:00',
      activities: data.activities.map(activity => {
        return {
          ...activity,
          duration_time: activity.duration_time.padEnd(8, ':00'),
        };
      }),
    };

    await dispatch(editServiceOrder({ id: params.id, serviceOrder }));
    setLoading(false);
  };

  useEffect(() => {
    if (params?.id) {
      navigation.setOptions({
        headerRight: () => {
          const [showMenu, setShowMenu] = useState(false);
          return (
            <Menu
              visible={showMenu}
              onDismiss={() => setShowMenu(false)}
              anchor={
                <IconButton icon="dots-vertical" onPress={() => setShowMenu(true)} size={25} />
              }
              statusBarHeight={
                Platform.OS === 'android' ? NativeModules?.StatusBarManager?.HEIGHT : undefined
              }>
              <Menu.Item
                leadingIcon="email-fast"
                onPress={() => {
                  setShowMenu(false);
                  setShowEmailDialog(true);
                }}
                title="Enviar por e-mail"
              />
              <Divider />
              <Menu.Item
                leadingIcon="delete"
                onPress={() => {
                  setShowMenu(false);
                  setShowConfirmAlert(true);
                }}
                title="Excluir"
              />
            </Menu>
          );
        },
      });

      loadData();
    }
  }, []);

  if (loadingData) {
    return (
      <View style={styles.centeredContainer}>
        <Loading size="large" />
      </View>
    );
  }

  return (
    <ScrollView style={styles.container}>
      <ServiceOrderForm
        initialValues={initialValues}
        loading={loading || loadingApproval}
        onApprove={handleOnApprove}
        onSubmit={onSubmit}
        showApproveOptions={isCoordinator}
        viewMode={!isConsultant}
      />
      <ConfirmDialog
        message="Você deseja excluir a ordem de serviço?"
        onConfirm={handleDeleteServiceOrder}
        onDismiss={() => setShowConfirmAlert(false)}
        visible={showConfirmAlert}
      />
      <Dialog
        confirmText="Enviar"
        onConfirm={sendEmail}
        onDismiss={() => setShowEmailDialog(false)}
        title="Enviar por e-mail"
        visible={showEmailDialog}>
        <Input label="E-mail" onChangeText={setEmail} value={email} />
      </Dialog>
    </ScrollView>
  );
}
