import axios, { AxiosError } from 'axios';
import moment, { Moment } from 'moment';
import qs from 'qs';
import {
  formatCurrency as formatCurrencyBR,
  parseCurrency as parseCurrencyBR,
} from '@brazilian-utils/brazilian-utils';

import toastUtil from './toast';
import { ResponseMeta } from '../types';

export const DEFAULT_PAGINATION_DATA: ResponseMeta['pagination'] = {
  page: 1,
  pageCount: 0,
  pageSize: 25,
  total: 0,
};

export const blobToDataUrl = async (blob: Blob): Promise<string | ArrayBuffer | null> =>
  new Promise((res, rej) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      res(reader.result);
    };
    reader.onerror = error => {
      rej(error);
    };
    reader.readAsDataURL(blob);
  });

export const calculateTimeDifference = (time1: string, time2: string) => {
  const [hour1, minute1] = time1.split(':').map(Number);
  const [hour2, minute2] = time2.split(':').map(Number);

  const totalMinutes1 = hour1 * 60 + minute1;
  const totalMinutes2 = hour2 * 60 + minute2;

  let differenceMinutes = Math.abs(totalMinutes1 - totalMinutes2);
  const differenceHours = Math.floor(differenceMinutes / 60);
  differenceMinutes %= 60;

  return { hours: differenceHours, minutes: differenceMinutes };
};

export const normalizeErrorMessage = (error: Error | AxiosError | any) => {
  let message = error?.message;

  if (axios.isAxiosError(error)) {
    if (error?.response?.data) {
      const data = error.response.data;
      if (data?.error?.message) {
        message = data.error.message;
      }
    }
  }

  return message;
};

export const formatCurrency = (value: number) => {
  if (!value) {
    value = 0;
  }

  return formatCurrencyBR(value);
};

export const parseCurrency = (value: string) => {
  if (!value) {
    value = '0';
  }

  return parseCurrencyBR(value);
};

export const formatTimeToMinutes = (time: string) => {
  if (!time) return 0;
  return moment(time, 'HH:mm').diff(moment().startOf('day'), 'minutes');
};

export const formatMinutesToTime = (minutes: number) => {
  if (!minutes) return '00:00';
  return moment().startOf('day').add(minutes, 'minutes').format('HH:mm');
};

export const formatDateToISOString = (date: Date | Moment) => {
  if (!date) return '';
  return moment(date).local(true).format('YYYY-MM-DD');
};

export const formatDateToLocaleString = (date: Date) =>
  formatISOStringToLocaleString(formatDateToISOString(date));

export const formatISOStringToLocaleString = (value: string) => {
  if (!value) return '';
  return value.split('-').reverse().join('/');
};

export const formatLocaleStringToISOString = (value: string) => {
  if (!value) return '';
  return value.split('/').reverse().join('-');
};

export const formatFilterObjectToString = (value: { [key: string]: any }) =>
  qs.stringify(value, {
    encode: false,
  });

export const removeAccents = (value: string) =>
  value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const showToast = (message: string) => {
  toastUtil.show(message);
};
