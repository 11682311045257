import React, { useEffect } from 'react';
import { FlatList, ListRenderItem, RefreshControl } from 'react-native';
import { useSelector } from 'react-redux';
import { Divider } from 'react-native-paper';
import { StackScreenProps } from '@react-navigation/stack';
import { CompositeScreenProps } from '@react-navigation/native';
import { MaterialBottomTabScreenProps } from '@react-navigation/material-bottom-tabs';

import { AddRecordsButton, Container, ListItem, Loading } from '../../../components';
import styles from './styles';
import { coordinatorSelector } from '../../../store/selectors/coordinator';
import { useAppDispatch } from '../../../store';
import { getCoordinators } from '../../../store/actions/coordinator';
import { UserStackParamList } from '../../../routes/user';
import { Coordinator } from '../../../types/coordinator';

type Props = CompositeScreenProps<
  MaterialBottomTabScreenProps<UserStackParamList, 'CoordinatorsHome'>,
  StackScreenProps<UserStackParamList>
>;

export default function CoordinatorsHome({ navigation }: Props) {
  const dispatch = useAppDispatch();
  const coordinatorState = useSelector(coordinatorSelector);
  const loading = coordinatorState?.loading;
  const coordinators = coordinatorState?.list || [];

  const loadCoordinators = () => {
    dispatch(getCoordinators());
  };

  useEffect(() => {
    loadCoordinators();
  }, []);

  const renderItem: ListRenderItem<Coordinator> = ({ item }) => {
    return (
      <ListItem
        title={item?.name}
        description={item?.email}
        leftIcon="account"
        onPress={() => navigation.navigate('CoordinatorDetails', { user: item })}
        rightIcon="chevron-right"
      />
    );
  };

  return (
    <Container flexGrow>
      <FlatList
        contentContainerStyle={styles.container}
        data={coordinators}
        ItemSeparatorComponent={Divider}
        keyExtractor={item => `${item?.id}`}
        refreshControl={<RefreshControl refreshing={loading} onRefresh={loadCoordinators} />}
        renderItem={renderItem}
      />
      {loading && <Loading />}
      <AddRecordsButton />
    </Container>
  );
}
