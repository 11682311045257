import React, { useState } from 'react';
import { Platform, View } from 'react-native';
import { useSelector } from 'react-redux';
import { Avatar, Divider, List, Switch, Text } from 'react-native-paper';
import { getVersion } from 'react-native-device-info';
import { MaterialBottomTabScreenProps } from '@react-navigation/material-bottom-tabs';
import { CompositeScreenProps } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';

import { ConfirmDialog, Container, ListItem, Spacer } from '../../../components';
import { useAppDispatch } from '../../../store';
import { logout, toggleDarkMode } from '../../../store/actions/auth';
import {
  companySelector,
  isDarkModeSelector,
  userSelector,
  userTypeSelector,
} from '../../../store/selectors/auth';
import styles from './styles';
import { UserStackParamList, UserTabParamList } from '../../../routes/user';

type Props = CompositeScreenProps<
  MaterialBottomTabScreenProps<UserTabParamList, 'Account'>,
  StackScreenProps<UserStackParamList>
>;

export default function AccountProfile({ navigation }: Props) {
  const dispatch = useAppDispatch();
  const user = useSelector(userSelector);
  const company = useSelector(companySelector);
  const userType = useSelector(userTypeSelector);
  const isDarkMode = useSelector(isDarkModeSelector);
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);

  const toggleTheme = () => {
    dispatch(toggleDarkMode());
  };

  return (
    <Container flexGrow safe spacingHorizontal="medium" spacingVertical="medium" webSmall>
      <List.Section>
        <View style={styles.centeredContainer}>
          <Avatar.Icon size={100} icon="account" />
        </View>
        <Spacer size="small" />
        <View style={styles.centeredContainer}>
          <Text variant="headlineMedium">{user.username}</Text>
          <Text variant="bodyMedium">
            {userType === 'consultant' && 'Consultor'}
            {userType === 'coordinator' && 'Coordenador'}
            {userType === 'master' && 'Administrador'}
          </Text>
          <Text variant="bodyMedium">{user.email}</Text>
          <Text variant="bodyMedium">Empresa: {company.name}</Text>
          <Text variant="bodyMedium">CNPJ: {company.cnpj}</Text>
        </View>
      </List.Section>
      <Spacer />
      <Divider />
      <List.Section>
        <ListItem
          title="Alterar Senha"
          leftIcon="lock"
          onPress={() => navigation.navigate('ChangePassword')}
        />
      </List.Section>
      <Divider />
      <List.Section>
        <List.Item
          title="Tema Escuro"
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onPress={Platform.OS === 'web' ? () => {} : undefined}
          left={({ color }) => <List.Icon color={color} icon="brightness-6" />}
          right={({ color }) => (
            <Switch value={isDarkMode} color={color} onValueChange={toggleTheme} />
          )}
        />
      </List.Section>
      <Divider />
      <List.Section>
        <ListItem title="Sair" leftIcon="logout" onPress={() => setShowConfirmAlert(true)} />
      </List.Section>
      <Divider />
      {Platform.OS !== 'web' && (
        <List.Section>
          <List.Subheader>Versão: {getVersion()}</List.Subheader>
        </List.Section>
      )}
      <ConfirmDialog
        message="Você deseja sair da sua conta?"
        onConfirm={() => dispatch(logout())}
        onDismiss={() => setShowConfirmAlert(false)}
        visible={showConfirmAlert}
      />
    </Container>
  );
}
