import axios, { InternalAxiosRequestConfig } from 'axios';
import { store } from '../store';
import { authenticatedSelector, tokenSelector } from '../store/selectors/auth';

// TODO: configurar variável de ambiente
const api = axios.create({
  baseURL: 'https://ordem-servico-consultorias.herokuapp.com', // Production
  // baseURL: 'https://ordem-servico-staging-c73fcd89b945.herokuapp.com', // Development
});

api.interceptors.request.use((config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const authenticated = authenticatedSelector(store.getState());
  if (authenticated) {
    const token = tokenSelector(store.getState());
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
});

export default api;
