import React, { useState } from 'react';

import SearchModal from '../search-modal';
import {
  DEFAULT_PAGINATION_DATA,
  formatDateToISOString,
  formatFilterObjectToString,
  formatISOStringToLocaleString,
  normalizeErrorMessage,
} from '../../utils';
import useDebounce from '../../hooks/useDebounce';
import { getServiceOrders } from '../../services/service-order';
import { PageOptions, ServiceOrder } from '../../types/service-order';
import { QuickDateResult } from '../date-quick-filters';

type ServiceOrderResult = ServiceOrder & { description?: string };

type Props = {
  multiSelect?: boolean;
  onDismiss: () => void;
  onSelect: (project: ServiceOrderResult | ServiceOrderResult[]) => void;
  additionalFilters?: { [key: string]: any };
  visible: boolean;
};

export default function ServiceOrderModal({
  additionalFilters,
  multiSelect = false,
  onDismiss,
  onSelect,
  visible,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('');
  const [dateFilter, setDateFilter] = useState<PageOptions['filters'] | undefined>(undefined);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION_DATA);
  const [serviceOrders, setServiceOrders] = useState<ServiceOrder[]>([]);

  const loadProjects = async (page: number) => {
    setLoading(true);
    try {
      const filters: { [key: string]: any } = {
        $or: [
          {
            id: {
              $containsi: filter,
            },
          },
          {
            date: {
              $containsi: filter.split('/').reverse().join('-'),
            },
          },
          {
            customer: {
              name: {
                $containsi: filter,
              },
            },
          },
        ],
        ...additionalFilters,
      };

      if (dateFilter?.dateId) {
        filters.date = {
          $gte: dateFilter?.fromDate,
          $lte: dateFilter?.toDate,
        };
      }

      const { data } = await getServiceOrders({
        filters: formatFilterObjectToString({ filters }),
        page,
      });
      setPagination(data.meta.pagination);
      const normalizedData: ServiceOrderResult[] = data.data.map(serviceOrder => {
        return {
          ...serviceOrder.attributes,
          id: serviceOrder.id,
          description: `OS: ${serviceOrder.id} | ${formatISOStringToLocaleString(
            serviceOrder.attributes.date
          )}`,
        };
      });
      if (page === 1) {
        setServiceOrders(normalizedData);
      } else {
        setServiceOrders(current => {
          return [...current, ...normalizedData];
        });
      }
    } catch (error) {
      setServiceOrders([]);
      console.log(normalizeErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const loadNextPage = () => {
    const nextPage = pagination.page + 1;
    if (nextPage <= pagination.pageCount) {
      loadProjects(nextPage);
    }
  };

  const handleOnSelect = (project: ServiceOrderResult | ServiceOrderResult[]) => {
    onSelect(project);
  };

  const handleOnQuickDateSelect = ({ id, fromDate, toDate }: QuickDateResult) => {
    setDateFilter({
      dateId: id,
      fromDate: fromDate ? formatDateToISOString(fromDate) : undefined,
      toDate: toDate ? formatDateToISOString(toDate) : undefined,
    });
  };

  useDebounce(
    () => {
      loadProjects(1);
    },
    500,
    [filter, dateFilter, additionalFilters]
  );

  return (
    <SearchModal
      items={serviceOrders}
      loading={loading}
      multiSelect={multiSelect}
      onDismiss={onDismiss}
      onEndReached={loadNextPage}
      onFilter={setFilter}
      onSelect={handleOnSelect}
      onQuickDateSelect={handleOnQuickDateSelect}
      visible={visible}
      showQuickDate
    />
  );
}
