import api from './api';

import {
  ChangePasswordParams,
  ChangePasswordResponse,
  LoginParams,
  LoginResponse,
  RegisterParams,
  RegisterResponse,
  VerifyFirstLoginResponse,
} from '../types/auth';

export const register = async (params: RegisterParams) => {
  return api.post<RegisterResponse>('/signup-master/signup', { data: params });
};

export const login = async (params: LoginParams) => {
  return api.post<LoginResponse>('/login', params);
};

export const verifyFirstLogin = async () => {
  return api.get<VerifyFirstLoginResponse>('/api/verify-first-accesses');
};

export const recoverAccount = async (email: string) => {
  return api.post<{ ok: boolean }>('/forgot-password', { email });
};

export const changePassword = async (params: ChangePasswordParams) => {
  return api.post<ChangePasswordResponse>('/api/auth/change-password', params);
};
