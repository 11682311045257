import React from 'react';
import { StackScreenProps } from '@react-navigation/stack';

import { Container } from '../../../components';
import UserForm from '../components/user-form';
import { UserStackParamList } from '../../../routes/user';
import styles from './styles';

type Props = StackScreenProps<UserStackParamList, 'CoordinatorDetails'>;
export default function CoordinatorDetails({ route }: Props) {
  const { user } = route.params;
  return (
    <Container
      flexGrow
      spacingHorizontal="medium"
      spacingVertical="medium"
      style={styles.container}>
      <UserForm
        disabled
        initialValues={{
          name: user.name,
          username: user?.username,
          email: user?.email,
        }}
      />
    </Container>
  );
}
