import { StyleSheet } from 'react-native';
import { MD3Theme } from 'react-native-paper';

const styles = (theme: MD3Theme) => {
  return StyleSheet.create({
    container: { justifyContent: 'center' },
    goToLoginText: { color: theme.colors.primary },
    loginText: { alignSelf: 'center' },
  });
};

export default styles;
