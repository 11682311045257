import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import * as api from '../../services/customer';
import { setError } from './error';
import { formatFilterObjectToString, normalizeErrorMessage, showToast } from '../../utils';
import { Customer, GetCustomersParams, PageOptions } from '../../types/customer';
import { goBack } from '../../utils/navigation';
import { customersOptionsSelector } from '../selectors/customer';
import { RootState } from '..';

export const getCustomers = createAsyncThunk(
  'customer/getCustomers',
  async (params: GetCustomersParams, { dispatch, getState }) => {
    try {
      const options = customersOptionsSelector(getState() as RootState);

      const filter = options.filters.filter ?? '';
      const filters = formatFilterObjectToString({
        filters: {
          $or: [
            {
              name: {
                $containsi: filter,
              },
            },
            {
              email: {
                $containsi: filter,
              },
            },
          ],
        },
      });
      const { data } = await api.getCustomers({ ...params, filters, orderBy: options.orderBy });
      return data;
    } catch (error) {
      dispatch(setError(normalizeErrorMessage(error)));
    }
  }
);

export const addCustomer = createAsyncThunk(
  'customer/addCustomer',
  async (customer: Customer, { dispatch }) => {
    try {
      const { data } = await api.addCustomer(customer);
      showToast('Cliente incluído com sucesso');
      goBack();
      dispatch(getCustomers({}));
      return data;
    } catch (error) {
      dispatch(setError(normalizeErrorMessage(error)));
    }
  }
);

export const editCustomer = createAsyncThunk(
  'customer/editCustomer',
  async ({ id, customer }: { id: number; customer: Customer }, { dispatch }) => {
    try {
      const { data } = await api.editCustomer(id, customer);
      showToast('Cliente editado com sucesso');
      goBack();
      dispatch(getCustomers({}));
      return data;
    } catch (error) {
      dispatch(setError(normalizeErrorMessage(error)));
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  'customer/deleteCustomer',
  async (id: number, { dispatch }) => {
    try {
      const { data } = await api.deleteCustomer(id);
      showToast('Cliente excluído com sucesso');
      goBack();
      dispatch(getCustomers({}));
      return data;
    } catch (error) {
      dispatch(setError(normalizeErrorMessage(error)));
    }
  }
);

export const setOptions = createAction<PageOptions>('customer/setOptions');

export const setFilter = createAction<string>('customer/setFilter');
