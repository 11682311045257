import React from 'react';
import { TimePickerModal } from 'react-native-paper-dates';

type TimePickerResponse = {
  hours: number;
  minutes: number;
};

export type TimePickerResult = {
  hours: number;
  hoursString: string;
  minutes: number;
  minutesString: string;
};

type Props = {
  visible: boolean;
  onClose: () => void;
  onSelect: (data: TimePickerResult) => void;
};

export default function TimePicker({ visible, onClose, onSelect }: Props) {
  const handleOnClose = () => {
    onClose && onClose();
  };

  const handleOnSelect = (data: TimePickerResponse) => {
    const result = {
      hours: data.hours,
      hoursString: data.hours.toString().padStart(2, '0'),
      minutes: data.minutes,
      minutesString: data.minutes.toString().padStart(2, '0'),
    };
    onSelect && onSelect(result);
  };

  return (
    <TimePickerModal
      onConfirm={handleOnSelect}
      onDismiss={handleOnClose}
      locale="pt"
      visible={visible}
    />
  );
}
