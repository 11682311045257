import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ScrollView } from 'react-native';
import { IconButton } from 'react-native-paper';
import { StackScreenProps } from '@react-navigation/stack';

import styles from './styles';
import { ProjectWithRelations } from '../../../types/project';
import { formatCurrency, formatLocaleStringToISOString, parseCurrency } from '../../../utils';
import { UserStackParamList } from '../../../routes/user';
import { deleteProject, editProject } from '../../../store/actions/project';
import { useAppDispatch } from '../../../store';
import ProjectForm, { FormData } from '../components/project-form';
import { ConfirmDialog } from '../../../components';
import { isMasterSelector } from '../../../store/selectors/auth';

type Props = StackScreenProps<UserStackParamList, 'ProjectDetails'>;

export default function ProjectDetails({ route, navigation }: Props) {
  const dispatch = useAppDispatch();
  const isMaster = useSelector(isMasterSelector);
  const { params } = route;
  const [loading, setLoading] = useState(false);
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);

  const handleDeleteProject = () => {
    setShowConfirmAlert(false);
    dispatch(deleteProject(params.id as number));
  };

  const onSubmit = async (data: FormData) => {
    setLoading(true);
    const hoursTradedString = data.hours_traded as string;
    const project: ProjectWithRelations = {
      ...data,
      hours_traded: hoursTradedString.endsWith(':00')
        ? hoursTradedString
        : `${hoursTradedString}:00`,
      hour_value: parseCurrency(data.hour_value as string),
      status: data.status || 'aberto',
      tasks: data.tasks.map(task => {
        return {
          ...task,
          hours: task.hours.endsWith(':00') ? task.hours : `${task.hours}:00`,
        };
      }),
    };

    await dispatch(editProject({ id: params.id as number, project }));
    setLoading(false);
  };

  useEffect(() => {
    if (params?.id && isMaster) {
      navigation.setOptions({
        headerRight: () => (
          <IconButton icon="delete" size={25} onPress={() => setShowConfirmAlert(true)} />
        ),
      });
    }
  }, []);

  return (
    <ScrollView style={styles.container}>
      <ProjectForm
        initialValues={{
          customerId: params?.customer?.id as number,
          customerName: params?.customer?.name as string,
          coordinatorId: params?.coordinator?.id as number,
          coordinatorName: params?.coordinator?.name as string,
          start: formatLocaleStringToISOString(params.start),
          end: formatLocaleStringToISOString(params.end),
          title: params.title,
          description: params.description,
          premises: params.premises,
          restrictions: params.restrictions,
          status: params.status,
          tasks: params.tasks?.map(task => {
            return {
              description: task.description,
              hours: String(task.hours),
            };
          }),
          hours_traded: params.hours_traded?.toString(),
          hour_value: formatCurrency(params.hour_value as number),
        }}
        loading={loading}
        onSubmit={onSubmit}
        viewMode={!isMaster}
      />
      <ConfirmDialog
        message="Você deseja excluir o projeto?"
        onConfirm={handleDeleteProject}
        onDismiss={() => setShowConfirmAlert(false)}
        visible={showConfirmAlert}
      />
    </ScrollView>
  );
}
