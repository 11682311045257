import { createReducer } from '@reduxjs/toolkit';

import { getProjects, setFilter, setOptions } from '../actions/project';
import { PageOptions, Project } from '../../types/project';
import { DEFAULT_PAGINATION_DATA } from '../../utils';

const INITIAL_STATE = {
  loading: false,
  list: [] as Project[],
  options: {
    filters: {
      filter: '',
    },
    orderBy: 'title',
  } as PageOptions,
  pagination: DEFAULT_PAGINATION_DATA,
};

export const reducer = createReducer(INITIAL_STATE, builder => {
  builder
    .addCase(getProjects.pending, state => {
      state.loading = true;
    })
    .addCase(getProjects.rejected, state => {
      state.loading = false;
    })
    .addCase(getProjects.fulfilled, (state, { payload }) => {
      if (payload) {
        if (payload.meta.pagination.page === 1) {
          state.list = payload.data;
        } else {
          state.list = [...state.list, ...payload.data];
        }
        state.pagination = payload.meta.pagination;
      }
      state.loading = false;
    })
    .addCase(setOptions, (state, { payload }) => {
      state.options = payload;
    })
    .addCase(setFilter, (state, { payload }) => {
      state.options.filters.filter = payload;
    });
});
