import { StyleSheet } from 'react-native';
import { spacing } from '../../theme/tokens';

const styles = StyleSheet.create({
  input: {
    marginTop: spacing.xsmall, // 4
    textAlign: 'auto',
  },
});

export default styles;
