import { createNavigationContainerRef } from '@react-navigation/native';

import { AuthStackParamList } from '../routes/auth';
import { UserStackParamList, UserTabParamList } from '../routes/user';

export const navigationRef = createNavigationContainerRef<
  AuthStackParamList & UserTabParamList & UserStackParamList
>();

export function navigate(name: keyof AuthStackParamList | keyof UserStackParamList, params?: any) {
  if (navigationRef.isReady()) {
    navigationRef.navigate(name, params);
  }
}

export function goBack() {
  if (navigationRef.isReady()) {
    navigationRef.goBack();
  }
}
