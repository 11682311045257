import React, { useEffect } from 'react';
import { FlatList, ListRenderItem, RefreshControl } from 'react-native';
import { useSelector } from 'react-redux';
import { Divider } from 'react-native-paper';
import { StackScreenProps } from '@react-navigation/stack';
import { CompositeScreenProps } from '@react-navigation/native';
import { MaterialBottomTabScreenProps } from '@react-navigation/material-bottom-tabs';

import { AddRecordsButton, Container, ListItem, Loading } from '../../../components';
import styles from './styles';
import { Consultant } from '../../../types/consultant';
import { consultantSelector } from '../../../store/selectors/consultant';
import { useAppDispatch } from '../../../store';
import { getConsultants } from '../../../store/actions/consultant';
import { UserStackParamList } from '../../../routes/user';

type Props = CompositeScreenProps<
  MaterialBottomTabScreenProps<UserStackParamList, 'ConsultantsHome'>,
  StackScreenProps<UserStackParamList>
>;

export default function ConsultantsHome({ navigation }: Props) {
  const dispatch = useAppDispatch();
  const consultantState = useSelector(consultantSelector);
  const loading = consultantState?.loading;
  const consultants = consultantState?.list || [];

  const loadConsultants = () => {
    dispatch(getConsultants());
  };

  useEffect(() => {
    loadConsultants();
  }, []);

  const renderItem: ListRenderItem<Consultant> = ({ item }) => {
    return (
      <ListItem
        title={item.name}
        description={item?.user?.email}
        leftIcon="account"
        onPress={() => navigation.navigate('ConsultantDetails', { user: item })}
        rightIcon="chevron-right"
      />
    );
  };

  return (
    <Container flexGrow>
      <FlatList
        contentContainerStyle={styles.container}
        data={consultants}
        ItemSeparatorComponent={Divider}
        keyExtractor={item => `${item?.id}`}
        refreshControl={<RefreshControl refreshing={loading} onRefresh={loadConsultants} />}
        renderItem={renderItem}
      />
      {loading && <Loading />}
      <AddRecordsButton />
    </Container>
  );
}
