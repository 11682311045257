import { MD3DarkTheme, MD3LightTheme, MD3Theme } from 'react-native-paper';
import { NavigationTheme } from 'react-native-paper/lib/typescript/types';

import { darkColors, lightColors } from './colors';

export const darkTheme = {
  ...MD3DarkTheme,
  colors: darkColors,
};

export const lightTheme = {
  ...MD3LightTheme,
  colors: lightColors,
};

export const normalizeNavigationTheme = (theme: MD3Theme) => {
  return {
    dark: theme.dark,
    colors: {
      primary: theme.colors.primary,
      background: theme.colors.background,
      card: theme.colors.background, // TODO: verificar se está correto
      text: theme.colors.onBackground, // TODO: verificar se está correto
      border: theme.colors.onSurface, // TODO: verificar se está correto
      notification: theme.colors.onSurface, // TODO: verificar se está correto
    },
  } as NavigationTheme;
};
