import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FlatList, ListRenderItem, RefreshControl } from 'react-native';
import { Appbar, Divider, IconButton, Searchbar } from 'react-native-paper';
import { StackScreenProps } from '@react-navigation/stack';
import { CompositeScreenProps } from '@react-navigation/native';
import { MaterialBottomTabScreenProps } from '@react-navigation/material-bottom-tabs';

import { AddRecordsButton, Container, ListItem, Loading } from '../../../components';
import { useAppDispatch } from '../../../store';
import { UserStackParamList, UserTabParamList } from '../../../routes/user';
import styles from './styles';
import useDebounce from '../../../hooks/useDebounce';
import FiltersModal from '../components/filters-modal';
import { getInvoices, setFilter, setOptions } from '../../../store/actions/invoice';
import {
  invoiceSelector,
  invoicesListSelector,
  invoicesOptionsSelector,
  invoicesPaginationSelector,
} from '../../../store/selectors/invoice';
import { InvoiceData } from '../../../types/invoice';

type Props = CompositeScreenProps<
  MaterialBottomTabScreenProps<UserTabParamList, 'Invoices'>,
  StackScreenProps<UserStackParamList>
>;

export default function InvoicesHome({ navigation }: Props) {
  const dispatch = useAppDispatch();
  const invoicesList = useSelector(invoicesListSelector);
  const options = useSelector(invoicesOptionsSelector);
  const pagination = useSelector(invoicesPaginationSelector);
  const loading = !!useSelector(invoiceSelector)?.loading;
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [showFiltersModal, setShowFiltersModal] = useState(false);

  const loadInvoices = async (page = 1) => {
    dispatch(getInvoices({ page }));
  };

  const loadNextPage = () => {
    const nextPage = pagination.page + 1;
    if (nextPage <= pagination.pageCount) {
      loadInvoices(nextPage);
    }
  };

  const toggleShowSearchBar = () => {
    setShowSearchBar(oldValue => !oldValue);
  };

  const handleSetFilters = (data: { filters: { filter?: string }; orderBy: string }) => {
    dispatch(setOptions(data));
    setShowFiltersModal(false);
  };

  useDebounce(
    () => {
      loadInvoices(1);
    },
    500,
    [options.filters, options.orderBy]
  );

  const renderItem: ListRenderItem<InvoiceData> = ({ item }) => {
    return (
      <ListItem
        title={`Fatura: ${item.id}`}
        description={`Cliente: ${item?.customer?.name || ''}`}
        leftIcon="folder"
        onPress={() => navigation.navigate('InvoiceDetails', { invoice: item })}
        rightIcon="chevron-right"
      />
    );
  };

  return (
    <>
      <Appbar.Header>
        <Appbar.Content title="Faturas" />
        <Appbar.Action icon="magnify" onPress={toggleShowSearchBar} />
      </Appbar.Header>
      <Container flexGrow>
        {showSearchBar && (
          <Container flexRow spacingHorizontal="small" spacingVertical="small">
            <Searchbar
              autoCapitalize="none"
              onChangeText={(text: string) => dispatch(setFilter(text))}
              placeholder="Pesquisar"
              style={styles.flex}
              value={options.filters.filter ?? ''}
            />
            <IconButton icon="filter-variant" size={25} onPress={() => setShowFiltersModal(true)} />
          </Container>
        )}
        <FlatList
          contentContainerStyle={styles.container}
          data={invoicesList}
          ItemSeparatorComponent={Divider}
          keyExtractor={item => item.id.toString()}
          onEndReachedThreshold={0.2}
          onEndReached={loadNextPage}
          refreshControl={<RefreshControl refreshing={loading} onRefresh={loadInvoices} />}
          renderItem={renderItem}
        />
        {loading && <Loading />}
        <AddRecordsButton />
        <FiltersModal
          onConfirm={handleSetFilters}
          onDismiss={() => setShowFiltersModal(false)}
          visible={showFiltersModal}
        />
      </Container>
    </>
  );
}
