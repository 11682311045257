import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Button, Container, Input, Spacer } from '../../../components';
import styles from './styles';
import { useAppDispatch } from '../../../store';
import { recoverAccount } from '../../../store/actions/auth';
import { showToast } from '../../../utils';
import { StackScreenProps } from '@react-navigation/stack';
import { AuthStackParamList } from '../../../routes/auth';

const validationSchema = yup.object().shape({
  email: yup.string().required('Preencha o e-mail').email('Digite um endereço de e-mail válido'),
});

type FieldValues = {
  email: string;
};

type Props = StackScreenProps<AuthStackParamList, 'RecoverAccount'>;

export default function RecoverAccount({ navigation }: Props) {
  const dispatch = useAppDispatch();
  const { handleSubmit, control } = useForm<FieldValues>({
    resolver: yupResolver(validationSchema),
  });
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: FieldValues) => {
    setLoading(true);
    const { payload } = await dispatch(recoverAccount(data.email));
    const response = payload as { ok: boolean };
    if (response?.ok) {
      showToast('E-mail enviado com sucesso.');
      navigation.goBack();
    }
    setLoading(false);
  };

  return (
    <Container
      flexGrow
      safe
      spacingHorizontal="medium"
      style={styles.container}
      spacingVertical="medium"
      webSmall>
      <Input name="email" control={control} label="E-mail" leftIcon="email" />
      <Spacer size="medium" />
      <Button loading={loading} onPress={handleSubmit(onSubmit)}>
        Recuperar
      </Button>
    </Container>
  );
}
