import React from 'react';
import { useSelector } from 'react-redux';

import AuthRoutes from './auth';
import UserRoutes from './user';
import { authenticatedSelector } from '../store/selectors/auth';

export default function Routes() {
  const authenticated = useSelector(authenticatedSelector);
  return authenticated ? <UserRoutes /> : <AuthRoutes />;
}
