import React from 'react';
import { StyleSheet } from 'react-native';
import { Modal, Portal, Text } from 'react-native-paper';

import Loading from '../loading';
import Spacer from '../spacer';

type Props = {
  size?: 'small' | 'large' | number;
  title?: string;
  visible: boolean;
};

export default function LoadingModal({ size = 'small', title, visible }: Props) {
  return (
    <Portal>
      <Modal style={styles.container} visible={visible}>
        <Loading size={size} />
        <Spacer size="small" />
        <Text>{title ?? 'Carregando...'}</Text>
      </Modal>
    </Portal>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});
