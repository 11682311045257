import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import * as api from '../../services/invoice';
import { setError } from './error';
import { normalizeErrorMessage, showToast } from '../../utils';
import { AddInvoiceParams, GetInvoicesParams, PageOptions } from '../../types/invoice';
import { RootState } from '..';
import { invoicesOptionsSelector } from '../selectors/invoice';
import { goBack } from '../../utils/navigation';

export const addInvoice = createAsyncThunk(
  'invoice/addInvoice',
  async (params: AddInvoiceParams, { dispatch }) => {
    try {
      const { data } = await api.addInvoice(params);
      showToast('Fatura gerada com sucesso');
      goBack();
      dispatch(getInvoices({}));
      return data;
    } catch (error) {
      dispatch(setError(normalizeErrorMessage(error)));
    }
  }
);

export const getInvoices = createAsyncThunk(
  'invoice/getInvoices',
  async (params: GetInvoicesParams, { dispatch, getState }) => {
    try {
      const options = invoicesOptionsSelector(getState() as RootState);
      const filters = ''; // FIXME: filtros
      const { data } = await api.getInvoices({ ...params, filters, orderBy: options.orderBy });
      return data;
    } catch (error) {
      dispatch(setError(normalizeErrorMessage(error)));
    }
  }
);

export const deleteInvoice = createAsyncThunk(
  'invoice/deleteInvoice',
  async (id: number, { dispatch }) => {
    try {
      const { data } = await api.deleteInvoice(id);
      showToast('Fatura excluída com sucesso');
      goBack();
      dispatch(getInvoices({}));
      return data;
    } catch (error) {
      dispatch(setError(normalizeErrorMessage(error)));
    }
  }
);

export const setOptions = createAction<PageOptions>('invoice/setOptions');

export const setFilter = createAction<string>('invoice/setFilter');
