import React from 'react';
import { useSelector } from 'react-redux';
import { Appbar, Divider, List } from 'react-native-paper';
import { MaterialBottomTabScreenProps } from '@react-navigation/material-bottom-tabs';
import { CompositeScreenProps } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';

import { AddRecordsButton, Container } from '../../components';
import { UserStackParamList, UserTabParamList } from '../../routes/user';
import { isMasterSelector } from '../../store/selectors/auth';

type Props = CompositeScreenProps<
  MaterialBottomTabScreenProps<UserTabParamList, 'RecordsHome'>,
  StackScreenProps<UserStackParamList>
>;

export default function RecordsHome({ navigation }: Props) {
  const isMaster = useSelector(isMasterSelector);

  return (
    <>
      <Appbar.Header>
        <Appbar.Content title="Cadastros" />
      </Appbar.Header>
      <Container flexGrow>
        <List.Item
          title="Clientes"
          description="Gerenciar clientes"
          left={props => <List.Icon {...props} icon="account-tie" />}
          onPress={() => navigation.navigate('CustomerHome')}
          right={props => <List.Icon {...props} icon="chevron-right" />}
        />
        <Divider />
        <List.Item
          title="Projetos"
          description="Gerenciar projetos"
          left={props => <List.Icon {...props} icon="briefcase" />}
          onPress={() => navigation.navigate('ProjectHome')}
          right={props => <List.Icon {...props} icon="chevron-right" />}
        />
        <Divider />
        {isMaster && (
          <>
            <List.Item
              title="Consultores"
              description="Gerenciar consultores"
              left={props => <List.Icon {...props} icon="account-group" />}
              onPress={() => navigation.navigate('ConsultantsHome')}
              right={props => <List.Icon {...props} icon="chevron-right" />}
            />
            <Divider />
            <List.Item
              title="Coordenadores"
              description="Gerenciar coordenadores"
              left={props => <List.Icon {...props} icon="account-group" />}
              onPress={() => navigation.navigate('CoordinatorsHome')}
              right={props => <List.Icon {...props} icon="chevron-right" />}
            />
            <Divider />
          </>
        )}
        <AddRecordsButton />
      </Container>
    </>
  );
}
