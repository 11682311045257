import api from './api';

import { AddConsultantParams, Consultant } from '../types/consultant';
import { getCompanyId } from '../utils/auth';

export const addConsultant = async (params: AddConsultantParams) => {
  const projects = [] as number[];
  params.projects?.forEach(item => projects.push(item.id as number));

  return api.post<Consultant>('/api/masters/create-consultant', {
    data: {
      ...params,
      company: getCompanyId(),
      projects,
    },
  });
};

export const updateConsultant = async (id: number, params: AddConsultantParams) => {
  const projects = [] as number[];
  params.projects?.forEach(item => projects.push(item.id as number));

  return api.put<Consultant>(`/api/masters/update-consultant/${id}`, {
    data: {
      ...params,
      company: getCompanyId(),
      projects,
    },
  });
};

export const getConsultants = async () => {
  return api.get<Consultant[]>('/api/consultants');
};
