import { View } from 'react-native';
import React from 'react';
import { spacing } from '../../theme/tokens';

type Props = {
  size?: 'xxsmall' | 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
  horizontal?: boolean;
};

export default function Spacer({ size = 'medium', horizontal }: Props) {
  const styles = {
    ...(horizontal ? { width: spacing[size] } : { height: spacing[size] }),
  };
  return <View style={styles} />;
}
