import React, { useState } from 'react';

import SearchModal from '../search-modal';
import { CustomerData } from '../../types/customer';
import { getCustomers } from '../../services/customer';
import {
  DEFAULT_PAGINATION_DATA,
  formatFilterObjectToString,
  normalizeErrorMessage,
} from '../../utils';
import useDebounce from '../../hooks/useDebounce';

type Props = {
  onDismiss: () => void;
  onSelect: (customer: CustomerData) => void;
  visible: boolean;
};

export default function CustomersModal({ onDismiss, onSelect, visible }: Props) {
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('');
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION_DATA);
  const [customers, setCustomers] = useState<CustomerData[]>([]);

  const loadCustomers = async (page: number) => {
    setLoading(true);
    try {
      const filters = formatFilterObjectToString({
        filters: {
          $or: [
            {
              name: {
                $containsi: filter,
              },
            },
            {
              email: {
                $containsi: filter,
              },
            },
          ],
        },
      });
      const { data } = await getCustomers({ filters, page });
      setPagination(data.meta.pagination);
      const normalizedData = data.data.map(customer => {
        return { ...customer, description: customer.attributes.name };
      });
      if (page === 1) {
        setCustomers(normalizedData);
      } else {
        setCustomers(currentCustomers => {
          return [...currentCustomers, ...normalizedData];
        });
      }
    } catch (error) {
      setCustomers([]);
      console.log(normalizeErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const loadNextPage = () => {
    const nextPage = pagination.page + 1;
    if (nextPage <= pagination.pageCount) {
      loadCustomers(nextPage);
    }
  };

  const handleOnSelect = (customer: CustomerData & { description?: string }) => {
    onSelect(customer);
  };

  useDebounce(
    () => {
      loadCustomers(1);
    },
    500,
    [filter]
  );

  return (
    <SearchModal
      items={customers}
      loading={loading}
      onDismiss={onDismiss}
      onEndReached={loadNextPage}
      onFilter={setFilter}
      onSelect={handleOnSelect}
      visible={visible}
    />
  );
}
