import React, { useState } from 'react';
import { ScrollView } from 'react-native';

import styles from './styles';
import { useAppDispatch } from '../../../store';
import { ServiceOrderWithRelations } from '../../../types/service-order';
import { addServiceOrder } from '../../../store/actions/service-order';
import ServiceOrderForm, { FormData } from '../components/service-order-form';

export default function NewServiceOrder() {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: FormData) => {
    setLoading(true);

    const serviceOrder: ServiceOrderWithRelations = {
      ...data,
      amount_time: data.amount_time,
      start_time: data.start_time,
      end_time: data.end_time,
      break: data.break || '00:00:00',
      transfer: data.transfer || '00:00:00',
      activities: data.activities.map(activity => {
        return {
          ...activity,
          duration_time: activity.duration_time.padEnd(8, ':00'),
        };
      }),
    };
    await dispatch(addServiceOrder(serviceOrder));
    setLoading(false);
  };

  return (
    <ScrollView style={styles.container}>
      <ServiceOrderForm loading={loading} onSubmit={onSubmit} />
    </ScrollView>
  );
}
