import React from 'react';
import { DatePickerModal } from 'react-native-paper-dates';
import { CalendarDate } from 'react-native-paper-dates/lib/typescript/Date/Calendar';

type Props = {
  visible: boolean;
  onClose: () => void;
  onSelect: ({ startDate, endDate }: { startDate: Date; endDate: Date }) => void;
};

export default function RangeDatePicker({ visible, onClose, onSelect }: Props) {
  const date = new Date();

  const handleOnClose = () => {
    onClose && onClose();
  };

  const handleOnSelect = (range: { startDate: CalendarDate; endDate: CalendarDate }) => {
    onSelect?.({
      startDate: new Date(range.startDate as Date),
      endDate: new Date(range.endDate as Date),
    });
  };

  return (
    <DatePickerModal
      date={date}
      locale="pt"
      mode="range"
      startDate={date}
      endDate={date}
      onConfirm={handleOnSelect}
      onDismiss={handleOnClose}
      visible={visible}
    />
  );
}
