import React, { ReactNode } from 'react';
import { ScrollView, View } from 'react-native';
import Modal from 'react-native-modal';
import { IconButton, useTheme } from 'react-native-paper';

import styles from './styles';

type Props = {
  coverScreen?: boolean;
  children: ReactNode;
  scrollViewEnabled?: boolean;
  visible: boolean;
  onClose: () => void;
};

export default function BottomModal({
  coverScreen = true,
  children,
  scrollViewEnabled = false,
  visible,
  onClose,
}: Props) {
  const theme = useTheme();
  const handleOnClose = () => {
    onClose && onClose();
  };

  const modalStyles = styles(theme);

  return (
    <Modal
      animationIn="slideInUp"
      animationOut="slideOutDown"
      backdropOpacity={0.5}
      coverScreen={coverScreen}
      isVisible={visible}
      onBackdropPress={handleOnClose}
      onSwipeComplete={handleOnClose}
      propagateSwipe
      style={modalStyles.container}
      swipeDirection={['down']}
      testID={'modal'}>
      <View style={modalStyles.content}>
        <View style={modalStyles.header}>
          <IconButton icon="close" onPress={handleOnClose} style={modalStyles.closeButton} />
        </View>
        {scrollViewEnabled && (
          <ScrollView>
            <View style={modalStyles.innerContainer} onStartShouldSetResponder={() => true}>
              {children}
            </View>
          </ScrollView>
        )}
        {!scrollViewEnabled && (
          <View style={modalStyles.innerContainer} onStartShouldSetResponder={() => true}>
            {children}
          </View>
        )}
      </View>
    </Modal>
  );
}
