import { createReducer } from '@reduxjs/toolkit';

import { clearError, setError } from '../actions/error';

const INITIAL_STATE = {
  visible: false,
  message: '',
};

export const reducer = createReducer(INITIAL_STATE, builder => {
  builder
    .addCase(setError, (state, { payload }) => {
      state.visible = true;
      state.message = payload;
    })
    .addCase(clearError, () => {
      return INITIAL_STATE;
    });
});
