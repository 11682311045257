import { Dimensions, StyleSheet } from 'react-native';
import { spacing } from '../../theme/tokens';

const styles = StyleSheet.create({
  container: {
    maxHeight: Dimensions.get('window').height - 100,
  },
  dateQuickFilter: {
    marginHorizontal: 0,
    marginTop: spacing.small,
  },
  emptyContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: spacing.large,
  },
  itemIcon: {
    margin: 0,
  },
});

export default styles;
