import { Platform, StyleSheet } from 'react-native';
import { spacing, window } from '../../../theme/tokens';

const styles = StyleSheet.create({
  centeredContainer: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  container: {
    marginBottom: spacing.medium,
    paddingHorizontal: spacing.medium,
    width: Platform.OS === 'web' ? window.width / 2 : undefined,
  },
});

export default styles;
