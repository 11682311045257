import React from 'react';
import { ActivityIndicator } from 'react-native-paper';

type Props = {
  size?: 'small' | 'large' | number;
};

export default function Loading({ size = 'small' }: Props) {
  return <ActivityIndicator size={size} />;
}
