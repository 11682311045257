import { StyleSheet } from 'react-native';

import { spacing } from '../../../../theme/tokens';

const styles = StyleSheet.create({
  addIcon: {
    margin: 0,
  },
  projectsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: spacing.small,
  },
  projectsTitleContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: spacing.small,
  },
});

export default styles;
