import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FlatList, ListRenderItem, RefreshControl } from 'react-native';
import { Divider, IconButton, Searchbar } from 'react-native-paper';
import { StackScreenProps } from '@react-navigation/stack';

import { AddRecordsButton, Container, ListItem, Loading } from '../../../components';
import { useAppDispatch } from '../../../store';
import { UserStackParamList } from '../../../routes/user';
import {
  projectSelector,
  projectsListSelector,
  projectsOptionsSelector,
  projectsPaginationSelector,
} from '../../../store/selectors/project';
import { getProjects, setFilter, setOptions } from '../../../store/actions/project';
import { Project } from '../../../types/project';
import { normalizeProjectStatus } from '../../../utils/project';
import styles from './styles';
import useDebounce from '../../../hooks/useDebounce';
import FiltersModal from '../components/filters-modal';

type Props = StackScreenProps<UserStackParamList, 'ProjectHome'>;

export default function ProjectHome({ navigation }: Props) {
  const dispatch = useAppDispatch();
  const projectsList = useSelector(projectsListSelector);
  const options = useSelector(projectsOptionsSelector);
  const pagination = useSelector(projectsPaginationSelector);
  const loading = !!useSelector(projectSelector)?.loading;
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [showFiltersModal, setShowFiltersModal] = useState(false);

  const loadProjects = async (page = 1) => {
    dispatch(getProjects({ page }));
  };

  const loadNextPage = () => {
    const nextPage = pagination.page + 1;
    if (nextPage <= pagination.pageCount) {
      loadProjects(nextPage);
    }
  };

  const toggleShowSearchBar = () => {
    setShowSearchBar(oldValue => !oldValue);
  };

  const handleSetFilters = (data: { filters: { filter: string }; orderBy: string }) => {
    dispatch(setOptions(data));
    setShowFiltersModal(false);
  };

  useDebounce(
    () => {
      loadProjects(1);
    },
    500,
    [options.filters, options.orderBy]
  );

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => <IconButton icon="magnify" size={25} onPress={toggleShowSearchBar} />,
    });
  }, []);

  const renderItem: ListRenderItem<Project> = ({ item }) => {
    return (
      <ListItem
        description={`${normalizeProjectStatus(item.status)} - ${item.description}`}
        title={item.title}
        leftIcon="briefcase"
        onPress={() => navigation.navigate('ProjectDetails', item)}
        rightIcon="chevron-right"
      />
    );
  };

  return (
    <Container flexGrow>
      {showSearchBar && (
        <Container flexRow spacingHorizontal="small" spacingVertical="small">
          <Searchbar
            onChangeText={(text: string) => dispatch(setFilter(text))}
            placeholder="Pesquisar"
            style={styles.flex}
            value={options.filters.filter}
          />
          <IconButton icon="filter-variant" size={25} onPress={() => setShowFiltersModal(true)} />
        </Container>
      )}
      <FlatList
        contentContainerStyle={styles.container}
        data={projectsList}
        ItemSeparatorComponent={Divider}
        keyExtractor={item => `${item.id}_${item.title}`}
        onEndReachedThreshold={0.2}
        onEndReached={loadNextPage}
        refreshControl={<RefreshControl refreshing={loading} onRefresh={loadProjects} />}
        renderItem={renderItem}
      />
      {loading && <Loading />}
      <AddRecordsButton />
      <FiltersModal
        onConfirm={handleSetFilters}
        onDismiss={() => setShowFiltersModal(false)}
        visible={showFiltersModal}
      />
    </Container>
  );
}
