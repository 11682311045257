import React from 'react';
import { useForm } from 'react-hook-form';
import { Masks } from 'react-native-mask-input';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { isValidPhone } from '@brazilian-utils/brazilian-utils';

import { Button, Input, Spacer } from '../../../../components';
import { Customer } from '../../../../types/customer';

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required('Preencha o nome fantasia')
    .min(3, 'O nome fantasia deve ter no mínimo 3 dígitos'),
  cellphone: yup
    .string()
    .required('Preencha o telefone')
    .test('invalid-phone', 'Digite um telefone válido', value => isValidPhone(value)),
  email: yup.string().required('Preencha o e-mail').email('Digite um endereço de e-mail válido'),
});

type Props = {
  initialValues?: Partial<Customer>;
  loading?: boolean;
  onSubmit: (data: Customer) => void;
  viewMode?: boolean;
};
export default function CustomerForm({ initialValues, loading, onSubmit, viewMode }: Props) {
  const { handleSubmit, control } = useForm<Customer>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  return (
    <>
      <Input
        editable={!viewMode}
        name="name"
        control={control}
        label="Nome Fantasia"
        leftIcon="account"
      />
      <Input
        editable={!viewMode}
        name="cellphone"
        control={control}
        label="Telefone"
        leftIcon="phone"
        mask={Masks.BRL_PHONE}
      />
      <Input editable={!viewMode} name="email" control={control} label="E-mail" leftIcon="email" />
      <Spacer size="medium" />
      {!viewMode && (
        <Button loading={loading} onPress={handleSubmit(onSubmit)}>
          Salvar
        </Button>
      )}
    </>
  );
}
