import React, { useState } from 'react';
import { StackScreenProps } from '@react-navigation/stack';

import { Container } from '../../../components';
import UserForm from '../components/user-form';
import { UserStackParamList } from '../../../routes/user';
import styles from './styles';
import { Project } from '../../../types/project';
import { AddConsultantParams } from '../../../types/consultant';
import { useAppDispatch } from '../../../store';
import { updateConsultant } from '../../../store/actions/consultant';

type Props = StackScreenProps<UserStackParamList, 'ConsultantDetails'>;
export default function ConsultantDetails({ route }: Props) {
  const { user } = route.params;
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const onSubmit = async (data: AddConsultantParams) => {
    setLoading(true);
    await dispatch(updateConsultant({ id: user.id as number, params: data }));
    setLoading(false);
  };

  return (
    <Container
      flexGrow
      spacingHorizontal="medium"
      spacingVertical="medium"
      style={styles.container}>
      <UserForm
        editMode
        initialValues={{
          name: user.name,
          username: user.user?.username,
          email: user.user?.email,
          projects: user?.projects as Project[],
        }}
        loading={loading}
        onSubmit={onSubmit}
      />
    </Container>
  );
}
