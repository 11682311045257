import React, { useEffect, useState } from 'react';
import { FlatList, ListRenderItem, StyleProp, ViewStyle } from 'react-native';
import { Chip } from 'react-native-paper';

import styles from './styles';
import moment, { Moment } from 'moment';
import RangeDatePicker from '../range-date-picker';

export type QuickDateItem = {
  id?: 'current_month' | 'last_7_days' | 'last_14_days' | 'last_30_days' | 'custom';
  label: string;
};

export type QuickDateResult = {
  id?: QuickDateItem['id'];
  fromDate?: Moment;
  toDate?: Moment;
};

const getItems = (customLabel?: string) => {
  const items: QuickDateItem[] = [
    {
      label: 'Todos',
    },
    {
      id: 'current_month',
      label: 'Mês atual',
    },
    {
      id: 'last_7_days',
      label: 'Últimos 7 dias',
    },
    {
      id: 'last_14_days',
      label: 'Últimos 14 dias',
    },
    {
      id: 'last_30_days',
      label: 'Últimos 30 dias',
    },
    {
      id: 'custom',
      label: customLabel ?? 'Personalizado',
    },
  ];

  return items;
};

type Props = {
  customLabel?: string;
  onSelect: (data: QuickDateResult) => void;
  selected?: QuickDateItem['id'];
  style?: StyleProp<ViewStyle>;
};
export default function DateQuickFilters({ customLabel, onSelect, selected, style }: Props) {
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const [selectedId, setSelectedId] = useState(selected);

  const handleOnSelectDates = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
    const fromDate = moment(startDate).hour(0).minute(0).second(0).millisecond(0);
    const toDate = moment(endDate).hour(23).minute(59).second(59).millisecond(0);

    setDatePickerVisible(false);
    onSelect({ id: selectedId, fromDate, toDate });
  };

  const handleOnSelect = (item: QuickDateItem) => {
    setSelectedId(item.id);

    if (!item.id) {
      onSelect({});
      return;
    }

    if (item.id === 'custom') {
      setDatePickerVisible(true);
      return;
    }

    let fromDate = moment().hour(0).minute(0).second(0).millisecond(0);
    let toDate = moment().hour(0).minute(0).second(0).millisecond(0);

    if (item.id === 'current_month') {
      fromDate = moment().startOf('month');
      toDate = moment().endOf('month');
    } else if (item.id === 'last_7_days') {
      fromDate = fromDate.add(-7, 'days');
    } else if (item.id === 'last_14_days') {
      fromDate = fromDate.add(-14, 'days');
    } else if (item.id === 'last_30_days') {
      fromDate = fromDate.add(-30, 'days');
    }

    onSelect({ id: item.id, fromDate, toDate });
  };

  const renderItem: ListRenderItem<QuickDateItem> = ({ item }) => {
    return (
      <Chip
        onPress={() => handleOnSelect(item)}
        selected={item.id === selectedId}
        showSelectedOverlay>
        {item.label}
      </Chip>
    );
  };

  useEffect(() => {
    setSelectedId(selected);
  }, [selected]);

  return (
    <>
      <FlatList
        contentContainerStyle={styles.contentContainer}
        data={getItems(customLabel)}
        horizontal
        renderItem={renderItem}
        showsHorizontalScrollIndicator={false}
        style={[styles.container, style]}
      />

      <RangeDatePicker
        onClose={() => setDatePickerVisible(false)}
        onSelect={handleOnSelectDates}
        visible={datePickerVisible}
      />
    </>
  );
}
