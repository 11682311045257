import React, { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Chip, HelperText, IconButton, Text, useTheme } from 'react-native-paper';
import * as yup from 'yup';

import { Button, Input, ProjectsModal, ServiceOrderModal, Spacer } from '../../../../components';
import { Pressable, View } from 'react-native';
import styles from './styles';
import { Project } from '../../../../types/project';
import { AddInvoiceParams } from '../../../../types/invoice';
import { ServiceOrder } from '../../../../types/service-order';
import { formatISOStringToLocaleString } from '../../../../utils';
import { useAppDispatch } from '../../../../store';
import { setError } from '../../../../store/actions/error';

const validationSchema = yup.object().shape({
  projectTitle: yup.string().required('Selecione o projeto'),
  serviceOrders: yup.array().min(1, 'Informe no mínimo uma ordem de serviço'),
});

type FormData = {
  customerName: string;
  serviceOrders: ServiceOrder[];
  project: Project;
  projectId: number;
  projectTitle: string;
};

type Props = {
  disabled?: boolean;
  initialValues?: Partial<FormData>;
  loading?: boolean;
  onSubmit?: (data: AddInvoiceParams) => void;
};
export default function InvoiceForm({ disabled = false, initialValues, loading, onSubmit }: Props) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [projectId, setProjectId] = useState<number | undefined>(initialValues?.projectId);
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [showServiceOrderModal, setShowServiceOrderModal] = useState(false);
  const { control, formState, handleSubmit, setValue } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });
  const serviceOrdersArray = useFieldArray({
    name: 'serviceOrders',
    control,
    keyName: 'customId',
  });

  const handleSelectProject = (project: Project) => {
    setProjectId(project.id);
    setValue('projectId', project.id as number);
    setValue('projectTitle', project.title);
    setValue('customerName', project?.customer?.name as string);
    setValue('serviceOrders', []);

    setShowProjectModal(false);
  };

  const handleSelectServiceOrder = (serviceOrders: ServiceOrder[]) => {
    const newItems = [...serviceOrdersArray.fields] as ServiceOrder[];

    serviceOrders.forEach(serviceOrder => {
      const itemFound = newItems.find(item => item.id === serviceOrder.id);
      if (!itemFound) {
        newItems.push(serviceOrder);
      }
    });

    setValue('serviceOrders', newItems);
    setShowServiceOrderModal(false);
  };

  const openServiceOrdersModal = () => {
    if (!projectId) {
      dispatch(setError('Selecione o projeto antes de selecionar as ordens de serviço'));
      return;
    }

    setShowServiceOrderModal(true);
  };

  const handleOnSubmit = () => {
    onSubmit?.({
      orders: serviceOrdersArray.fields.map(serviceOrder => serviceOrder.id as number),
      project: projectId as number,
    });
  };

  return (
    <>
      <Pressable disabled={disabled} onPress={() => setShowProjectModal(true)}>
        <Input
          name="projectTitle"
          control={control}
          editable={false}
          label="Projeto"
          leftIcon="briefcase"
          rightIcon="menu-down"
        />
      </Pressable>
      <Input
        name="customerName"
        control={control}
        editable={false}
        label="Cliente"
        leftIcon="account-tie"
      />
      <Spacer size="medium" />
      <View style={styles.serviceOrdersTitleContainer}>
        <Text variant="titleSmall">Ordens de serviço</Text>
        {!disabled && (
          <IconButton
            containerColor={theme.colors.primary}
            icon="plus"
            iconColor={theme.colors.inversePrimary}
            style={styles.addIcon}
            onPress={openServiceOrdersModal}
          />
        )}
      </View>
      <View style={styles.projectsContainer}>
        {!!serviceOrdersArray.fields &&
          serviceOrdersArray.fields.map((item, index) => {
            return (
              <Chip
                disabled={disabled}
                key={item.customId}
                onClose={() => (!disabled ? serviceOrdersArray.remove(index) : undefined)}>
                {item.id} - {formatISOStringToLocaleString(item.date)}
              </Chip>
            );
          })}
      </View>

      {!!formState.errors?.serviceOrders && (
        <HelperText type="error">{formState.errors?.serviceOrders?.message}</HelperText>
      )}

      <Spacer size="medium" />
      {!!onSubmit && (
        <Button loading={loading} onPress={handleSubmit(handleOnSubmit)}>
          Salvar
        </Button>
      )}
      {!disabled && (
        <ProjectsModal
          onDismiss={() => setShowProjectModal(false)}
          onSelect={(project: Project | Project[]) => handleSelectProject(project as Project)}
          visible={showProjectModal}
        />
      )}
      {!disabled && !!projectId && (
        <ServiceOrderModal
          additionalFilters={{
            status: {
              $eq: 'APPROVED',
            },
            invoicedAt: {
              $null: true,
            },
            project: {
              id: {
                $eq: projectId,
              },
            },
          }}
          multiSelect
          onDismiss={() => setShowServiceOrderModal(false)}
          onSelect={(serviceOrders: ServiceOrder | ServiceOrder[]) =>
            handleSelectServiceOrder(serviceOrders as ServiceOrder[])
          }
          visible={showServiceOrderModal}
        />
      )}
    </>
  );
}
