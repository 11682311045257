import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import Login from '../pages/authentication/login';
import SignUp from '../pages/authentication/signup';
import RecoverAccount from '../pages/authentication/recover-account';

export type AuthStackParamList = {
  Login: undefined;
  SignUp: undefined;
  RecoverAccount: undefined;
};

const Stack = createStackNavigator<AuthStackParamList>();

export default function AuthRoutes() {
  return (
    <Stack.Navigator
      initialRouteName="Login"
      screenOptions={{
        animationEnabled: false,
        headerShown: false,
      }}>
      <Stack.Screen name="Login" component={Login} />
      <Stack.Screen
        name="SignUp"
        component={SignUp}
        options={{
          headerShown: true,
          title: 'Criar uma conta',
        }}
      />
      <Stack.Screen
        name="RecoverAccount"
        component={RecoverAccount}
        options={{
          headerShown: true,
          title: 'Recuperar conta',
        }}
      />
    </Stack.Navigator>
  );
}
