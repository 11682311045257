import React, { useState } from 'react';

import SearchModal from '../search-modal';
import { getProjects } from '../../services/project';
import { Project } from '../../types/project';
import {
  DEFAULT_PAGINATION_DATA,
  formatFilterObjectToString,
  normalizeErrorMessage,
} from '../../utils';
import useDebounce from '../../hooks/useDebounce';

type ProjectResult = Project & { description?: string };

type Props = {
  multiSelect?: boolean;
  onDismiss: () => void;
  onSelect: (project: ProjectResult | ProjectResult[]) => void;
  visible: boolean;
};

export default function ProjectsModal({
  multiSelect = false,
  onDismiss,
  onSelect,
  visible,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('');
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION_DATA);
  const [projects, setProjects] = useState<Project[]>([]);

  const loadProjects = async (page: number) => {
    setLoading(true);
    try {
      const filters = formatFilterObjectToString({
        filters: {
          $or: [
            {
              title: {
                $containsi: filter,
              },
            },
            {
              description: {
                $email: filter,
              },
            },
          ],
        },
      });
      const { data } = await getProjects({ filters, page });
      setPagination(data.meta.pagination);
      const normalizedData = data.data.map(project => {
        return { ...project, description: project.title };
      });
      if (page === 1) {
        setProjects(normalizedData);
      } else {
        setProjects(currentProjects => {
          return [...currentProjects, ...normalizedData];
        });
      }
    } catch (error) {
      setProjects([]);
      console.log(normalizeErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const loadNextPage = () => {
    const nextPage = pagination.page + 1;
    if (nextPage <= pagination.pageCount) {
      loadProjects(nextPage);
    }
  };

  const handleOnSelect = (project: ProjectResult | ProjectResult[]) => {
    onSelect(project);
  };

  useDebounce(
    () => {
      loadProjects(1);
    },
    500,
    [filter]
  );

  return (
    <SearchModal
      items={projects}
      loading={loading}
      multiSelect={multiSelect}
      onDismiss={onDismiss}
      onEndReached={loadNextPage}
      onFilter={setFilter}
      onSelect={handleOnSelect}
      visible={visible}
    />
  );
}
