import { Appearance } from 'react-native';
import { createReducer } from '@reduxjs/toolkit';

import { login, logout, toggleDarkMode, verifyFirstLogin } from '../actions/auth';
import { UserType } from '../../types/auth';

const INITIAL_STATE = {
  jwt: '',
  user: {
    blocked: false,
    confirmed: false,
    createdAt: '',
    email: '',
    id: 0,
    provider: '',
    updatedAt: '',
    username: '',
  },
  company: {
    id: 0,
    name: '',
    cnpj: '',
  },
  userType: '' as UserType,
  darkMode: Appearance.getColorScheme() === 'dark',
};

export const reducer = createReducer(INITIAL_STATE, builder => {
  builder
    .addCase(login.fulfilled, (state, { payload }) => {
      if (payload?.jwt) {
        state.jwt = payload.jwt;
      }
      if (payload?.user) {
        state.user = payload.user;
      }
      if (payload?.company) {
        state.company = payload.company;
      }
    })
    .addCase(verifyFirstLogin.fulfilled, (state, { payload }) => {
      state.userType = payload?.userType as UserType;
    })
    .addCase(logout, state => {
      return {
        ...INITIAL_STATE,
        darkMode: state.darkMode,
      };
    })
    .addCase(toggleDarkMode, state => {
      state.darkMode = !state.darkMode;
    });
});
