import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Snackbar } from 'react-native-paper';

import styles from './styles';

type ToastRef = { show: (message: string) => void };
const Toast = forwardRef<ToastRef>((_, ref) => {
  const [message, setMessage] = useState('');
  const [visible, setVisible] = useState(false);

  const onDismissSnackBar = () => {
    setVisible(false);
  };

  const show = (message: string) => {
    setMessage(message);
    setVisible(true);
  };

  useImperativeHandle(ref, () => {
    return {
      show,
    };
  });

  return (
    <Snackbar
      action={{
        label: 'Fechar',
      }}
      duration={3000}
      onDismiss={onDismissSnackBar}
      visible={visible}
      style={styles.toast}>
      {message}
    </Snackbar>
  );
});

Toast.displayName = 'Toast';
export default Toast;
