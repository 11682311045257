import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RefreshControl, SectionList, SectionListRenderItem } from 'react-native';
import { Appbar, Chip, Divider, IconButton, Searchbar, Text } from 'react-native-paper';
import { StackScreenProps } from '@react-navigation/stack';
import { CompositeScreenProps } from '@react-navigation/native';
import { MaterialBottomTabScreenProps } from '@react-navigation/material-bottom-tabs';

import { Container, DateQuickFilters, ListItem, Loading, Spacer } from '../../../components';
import { useAppDispatch } from '../../../store';
import { UserStackParamList, UserTabParamList } from '../../../routes/user';

import { formatDateToISOString, formatISOStringToLocaleString } from '../../../utils';
import styles from './styles';
import {
  listPendingOrders,
  setPendingOrdersDateFilters,
  setPendingOrdersFilter,
  setPendingOrdersOptions,
} from '../../../store/actions/coordinator';
import {
  coordinatorSelector,
  pendingOrdersOptionsSelector,
} from '../../../store/selectors/coordinator';
import { PendingOrder } from '../../../types/coordinator';
import useDebounce from '../../../hooks/useDebounce';
import FiltersModal from '../components/filters-modal';
import { statusColors } from '../../../theme/colors';
import { ServiceOrderStatus } from '../../../types/service-order';

type Props = CompositeScreenProps<
  MaterialBottomTabScreenProps<UserTabParamList, 'PendingOrdersHome'>,
  StackScreenProps<UserStackParamList>
>;

const getStatusName = (status: ServiceOrderStatus) => {
  switch (status) {
    case 'APPROVED':
      return 'Aprovado';
    case 'PENDING':
      return 'Pendente';
    case 'REFUSED':
      return 'Rejeitado';
    default:
      return 'Todos';
  }
};

export default function PendingOrdersHome({ navigation }: Props) {
  const dispatch = useAppDispatch();
  const options = useSelector(pendingOrdersOptionsSelector);
  const pendingOrdersData = useSelector(coordinatorSelector)?.pendingOrders;
  const loading = !!useSelector(coordinatorSelector)?.loadingPendingOrders;
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const customLabel =
    options?.filters?.dateId === 'custom'
      ? `${formatISOStringToLocaleString(
          options?.filters?.fromDate as string
        )} - ${formatISOStringToLocaleString(options?.filters?.toDate as string)}`
      : undefined;

  const loadData = async () => {
    dispatch(listPendingOrders({}));
  };

  const getSections = () => {
    const sections: {
      data: PendingOrder[];
      title: string;
    }[] = [];

    pendingOrdersData.forEach(item => {
      const data =
        pendingOrdersData.find(data => data.projectName === item.projectName)?.pendingOrders || [];

      if (data.length) {
        sections.push({
          data,
          title: item.projectName,
        });
      }
    });

    return sections;
  };

  const handleSetFilters = (data: { filters: { filter?: string }; orderBy: string }) => {
    dispatch(setPendingOrdersOptions(data));
    setShowFiltersModal(false);
  };

  const renderItem: SectionListRenderItem<PendingOrder> = ({ item }) => {
    const getIcon = () => {
      if (item.status === 'APPROVED') {
        return {
          color: statusColors.success,
          icon: 'check',
        };
      }

      if (item.status === 'REFUSED') {
        return {
          color: statusColors.danger,
          icon: 'close',
        };
      }

      return {
        color: statusColors.warning,
        icon: 'folder',
      };
    };

    const iconData = getIcon();
    return (
      <ListItem
        title={`OS: ${item.id} | ${formatISOStringToLocaleString(item.date)}`}
        description={`Cliente: ${item?.customer?.name || ''}`}
        leftIcon={iconData.icon}
        leftIconColor={iconData.color}
        onPress={() => navigation.navigate('ServiceOrderDetails', item)}
        rightIcon="chevron-right"
      />
    );
  };

  const toggleShowSearchBar = () => {
    setShowSearchBar(oldValue => !oldValue);
  };

  useDebounce(
    () => {
      loadData();
    },
    500,
    [options.filters, options.orderBy]
  );

  return (
    <>
      <Appbar.Header>
        <Appbar.Content title="Aprovações pendentes" />
        <Appbar.Action icon="magnify" onPress={toggleShowSearchBar} />
      </Appbar.Header>
      <Container flexGrow>
        <DateQuickFilters
          customLabel={customLabel}
          onSelect={({ id, fromDate, toDate }) => {
            dispatch(
              setPendingOrdersDateFilters({
                id,
                fromDate: fromDate ? formatDateToISOString(fromDate) : undefined,
                toDate: toDate ? formatDateToISOString(toDate) : undefined,
              })
            );
          }}
          selected={options?.filters?.dateId}
        />
        <Spacer size="small" />
        {showSearchBar && (
          <Container flexRow spacingHorizontal="small" spacingVertical="small">
            <Searchbar
              autoCapitalize="none"
              onChangeText={(text: string) => dispatch(setPendingOrdersFilter(text))}
              placeholder="Pesquisar"
              style={styles.flex}
              value={options.filters.filter ?? ''}
            />
            <IconButton icon="filter-variant" size={25} onPress={() => setShowFiltersModal(true)} />
          </Container>
        )}
        {!!options?.filters?.status && (
          <Container
            spacingHorizontal="medium"
            spacingVertical="small"
            style={styles.appliedFilterContainer}>
            <Text>Filtro aplicado:</Text>
            <Chip
              onClose={() =>
                dispatch(
                  setPendingOrdersOptions({
                    ...options,
                    filters: { ...options.filters, status: undefined },
                  })
                )
              }>
              {getStatusName(options?.filters?.status)}
            </Chip>
          </Container>
        )}
        <SectionList
          contentContainerStyle={styles.container}
          keyExtractor={item => `${item.id}`}
          ItemSeparatorComponent={Divider}
          refreshControl={<RefreshControl refreshing={loading} onRefresh={loadData} />}
          renderItem={renderItem}
          renderSectionHeader={({ section: { title } }) => (
            <Text variant="titleMedium">{title}</Text>
          )}
          sections={getSections()}
          stickySectionHeadersEnabled
        />
        {loading && <Loading />}
        <FiltersModal
          onConfirm={handleSetFilters}
          onDismiss={() => setShowFiltersModal(false)}
          visible={showFiltersModal}
        />
      </Container>
    </>
  );
}
