import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FlatList, ListRenderItem, RefreshControl } from 'react-native';
import { Divider, IconButton, Searchbar } from 'react-native-paper';
import { StackScreenProps } from '@react-navigation/stack';

import { AddRecordsButton, Container, ListItem, Loading } from '../../../components';
import { useAppDispatch } from '../../../store';
import { getCustomers, setFilter, setOptions } from '../../../store/actions/customer';
import {
  customerSelector,
  customersListSelector,
  customersOptionsSelector,
  customersPaginationSelector,
} from '../../../store/selectors/customer';
import { CustomerData } from '../../../types/customer';
import { UserStackParamList } from '../../../routes/user';
import styles from './styles';
import useDebounce from '../../../hooks/useDebounce';
import FiltersModal from '../components/filters-modal';

type Props = StackScreenProps<UserStackParamList, 'CustomerHome'>;

export default function CustomerHome({ navigation }: Props) {
  const dispatch = useAppDispatch();
  const customersList = useSelector(customersListSelector);
  const options = useSelector(customersOptionsSelector);
  const pagination = useSelector(customersPaginationSelector);
  const loading = !!useSelector(customerSelector)?.loading;
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [showFiltersModal, setShowFiltersModal] = useState(false);

  const loadCustomers = async (page = 1) => {
    await dispatch(getCustomers({ page }));
  };

  const loadNextPage = async () => {
    const nextPage = pagination.page + 1;
    if (nextPage <= pagination.pageCount) {
      await loadCustomers(nextPage);
    }
  };

  const toggleShowSearchBar = () => {
    setShowSearchBar(oldValue => !oldValue);
  };

  const handleSetFilters = (data: { filters: { filter: string }; orderBy: string }) => {
    dispatch(setOptions(data));
    setShowFiltersModal(false);
  };

  useDebounce(
    () => {
      loadCustomers(1);
    },
    500,
    [options.filters, options.orderBy]
  );

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => <IconButton icon="magnify" size={25} onPress={toggleShowSearchBar} />,
    });
  }, []);

  const renderItem: ListRenderItem<CustomerData> = ({ item }) => {
    return (
      <ListItem
        title={item.attributes.name}
        description={item.attributes.email}
        leftIcon="account-tie"
        onPress={() => navigation.navigate('CustomerDetails', item)}
        rightIcon="chevron-right"
      />
    );
  };

  return (
    <Container flexGrow>
      {showSearchBar && (
        <Container flexRow spacingHorizontal="small" spacingVertical="small">
          <Searchbar
            onChangeText={(text: string) => dispatch(setFilter(text))}
            placeholder="Pesquisar"
            style={styles.flex}
            value={options.filters.filter}
          />
          <IconButton icon="filter-variant" size={25} onPress={() => setShowFiltersModal(true)} />
        </Container>
      )}
      <FlatList
        contentContainerStyle={styles.container}
        data={customersList}
        ItemSeparatorComponent={Divider}
        keyExtractor={item => `${item.id}_${item.attributes.email}`}
        onEndReachedThreshold={0.2}
        onEndReached={loadNextPage}
        refreshControl={<RefreshControl refreshing={loading} onRefresh={loadCustomers} />}
        renderItem={renderItem}
      />
      {loading && <Loading />}
      <AddRecordsButton />
      <FiltersModal
        onConfirm={handleSetFilters}
        onDismiss={() => setShowFiltersModal(false)}
        visible={showFiltersModal}
      />
    </Container>
  );
}
