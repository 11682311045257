import React, { useState } from 'react';
import { ScrollView } from 'react-native';

import styles from './styles';
import { ProjectWithRelations } from '../../../types/project';
import { addProject } from '../../../store/actions/project';
import { useAppDispatch } from '../../../store';
import ProjectForm, { FormData } from '../components/project-form';
import { parseCurrency } from '../../../utils';

export default function NewProject() {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: FormData) => {
    setLoading(true);
    const hoursTradedString = data.hours_traded as string;
    const project: ProjectWithRelations = {
      ...data,
      hours_traded: hoursTradedString.endsWith(':00')
        ? hoursTradedString
        : `${hoursTradedString}:00`,
      hour_value: parseCurrency(data.hour_value as string),
      status: data.status || 'aberto',
      tasks: data.tasks.map(task => {
        return {
          ...task,
          hours: task.hours.endsWith(':00') ? task.hours : `${task.hours}:00`,
        };
      }),
    };

    await dispatch(addProject(project));
    setLoading(false);
  };

  return (
    <ScrollView style={styles.container}>
      <ProjectForm addMode loading={loading} onSubmit={onSubmit} />
    </ScrollView>
  );
}
