import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import * as api from '../../services/coordinator';
import { setError } from './error';
import { formatFilterObjectToString, normalizeErrorMessage, showToast } from '../../utils';
import { AddCoordinatorParams, PendingOrdersParams } from '../../types/coordinator';
import { goBack } from '../../utils/navigation';
import { PageOptions } from '../../types/service-order';
import { QuickDateItem } from '../../components/date-quick-filters';
import { pendingOrdersOptionsSelector } from '../selectors/coordinator';
import { RootState } from '..';

export const addCoordinator = createAsyncThunk(
  'coordinator/addCoordinator',
  async (params: AddCoordinatorParams, { dispatch }) => {
    try {
      const { data } = await api.addCoordinator(params);
      showToast('Coordenador cadastrado com sucesso');
      goBack();
      dispatch(getCoordinators());
      return data;
    } catch (error) {
      dispatch(setError(normalizeErrorMessage(error)));
    }
  }
);

export const getCoordinators = createAsyncThunk(
  'coordinator/getCoordinators',
  async (_, { dispatch }) => {
    try {
      const { data } = await api.getCoordinators();
      return data;
    } catch (error) {
      dispatch(setError(normalizeErrorMessage(error)));
    }
  }
);

export const listPendingOrders = createAsyncThunk(
  'coordinator/listPendingOrders',
  async (params: PendingOrdersParams, { dispatch, getState }) => {
    try {
      const options = pendingOrdersOptionsSelector(getState() as RootState);

      const filter = options.filters.filter ?? '';
      const filters: { [key: string]: any } = {
        $or: [
          {
            id: {
              $containsi: filter,
            },
          },
          {
            date: {
              $containsi: filter.split('/').reverse().join('-'),
            },
          },
          {
            customer: {
              name: {
                $containsi: filter,
              },
            },
          },
        ],
      };

      if (options?.filters?.dateId) {
        filters.date = {
          $gte: options?.filters?.fromDate,
          $lte: options?.filters?.toDate,
        };
      }

      if (options?.filters?.status) {
        filters.status = options?.filters?.status;
      }

      const { data } = await api.listPendingOrders({
        ...params,
        filters: formatFilterObjectToString({ filters }),
        orderBy: options.orderBy,
      });
      return data;
    } catch (error) {
      dispatch(setError(normalizeErrorMessage(error)));
    }
  }
);

export const approveServiceOrder = createAsyncThunk(
  'coordinator/approveServiceOrder',
  async (id: number, { dispatch }) => {
    try {
      const { data } = await api.approveServiceOrder(id);
      showToast('Ordem de serviço aprovada com sucesso');
      goBack();
      dispatch(listPendingOrders({}));
      return data;
    } catch (error) {
      dispatch(setError(normalizeErrorMessage(error)));
    }
  }
);

export const reproveServiceOrder = createAsyncThunk(
  'coordinator/reproveServiceOrder',
  async (id: number, { dispatch }) => {
    try {
      const { data } = await api.reproveServiceOrder(id);
      showToast('Ordem de serviço reprovada com sucesso');
      goBack();
      dispatch(listPendingOrders({}));
      return data;
    } catch (error) {
      dispatch(setError(normalizeErrorMessage(error)));
    }
  }
);

export const setPendingOrdersOptions = createAction<PageOptions>(
  'coordinator/setPendingOrdersOptions'
);

export const setPendingOrdersFilter = createAction<string>('coordinator/setPendingOrdersFilter');

export const setPendingOrdersDateFilters = createAction<{
  id?: QuickDateItem['id'];
  fromDate?: string;
  toDate?: string;
}>('coordinator/setPendingOrdersDateFilters');
