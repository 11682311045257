import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RadioButton, Searchbar, Text } from 'react-native-paper';

import { BottomModal, Button, Spacer } from '../../../../components';
import { invoicesOptionsSelector } from '../../../../store/selectors/invoice';

type Filters = {
  filter?: string;
};

type Props = {
  onConfirm: (data: { filters: Filters; orderBy: string }) => void;
  onDismiss: () => void;
  visible: boolean;
};

export default function FiltersModal({ onConfirm, onDismiss, visible }: Props) {
  const options = useSelector(invoicesOptionsSelector);
  const [filter, setFilter] = useState(options.filters.filter);
  const [orderBy, setOrderBy] = useState(options.orderBy || 'date:desc');

  const handleOnClean = () => {
    setFilter('');
    setOrderBy('date:desc');
    onConfirm &&
      onConfirm({
        filters: {
          filter: '',
        },
        orderBy: 'date:desc',
      });
  };

  const handleOnConfirm = () => {
    onConfirm &&
      onConfirm({
        filters: { filter },
        orderBy,
      });
  };

  return (
    <BottomModal visible={visible} onClose={onDismiss}>
      <Searchbar onChangeText={setFilter} placeholder="Pesquisar" value={filter ?? ''} />
      <Spacer />
      <Text variant="titleMedium">Ordenar por:</Text>
      <RadioButton.Group onValueChange={setOrderBy} value={orderBy}>
        <RadioButton.Item label="Data crescente" value="date" />
        <RadioButton.Item label="Data decrescente" value="date:desc" />
        <RadioButton.Item label="Número OS crescente" value="id" />
        <RadioButton.Item label="Número OS decrescente" value="id:desc" />
      </RadioButton.Group>
      <Spacer size="small" />
      <Button onPress={handleOnConfirm}>Filtrar</Button>
      <Button mode="text" onPress={handleOnClean}>
        Limpar filtros
      </Button>
    </BottomModal>
  );
}
