import { StyleSheet } from 'react-native';
import { spacing } from '../../theme/tokens';

const styles = StyleSheet.create({
  container: {
    flexGrow: 0,
    marginHorizontal: spacing.medium,
  },
  contentContainer: {
    gap: spacing.xsmall,
  },
});

export default styles;
