import { createReducer } from '@reduxjs/toolkit';

import { getServiceOrders, setDateFilters, setFilter, setOptions } from '../actions/service-order';
import { PageOptions, ServiceOrderData } from '../../types/service-order';
import { DEFAULT_PAGINATION_DATA } from '../../utils';

const INITIAL_STATE = {
  loading: false,
  list: [] as ServiceOrderData[],
  options: {
    filters: {
      filter: '',
      dateId: undefined,
      fromDate: undefined,
      toDate: undefined,
    },
    orderBy: 'date:desc',
  } as PageOptions,
  pagination: DEFAULT_PAGINATION_DATA,
};

export const reducer = createReducer(INITIAL_STATE, builder => {
  builder
    .addCase(getServiceOrders.pending, state => {
      state.loading = true;
    })
    .addCase(getServiceOrders.rejected, state => {
      state.loading = false;
    })
    .addCase(getServiceOrders.fulfilled, (state, { payload }) => {
      if (payload) {
        if (payload.meta.pagination.page === 1) {
          state.list = payload.data;
        } else {
          state.list = [...state.list, ...payload.data];
        }
        state.pagination = payload.meta.pagination;
      }
      state.loading = false;
    })
    .addCase(setDateFilters, (state, { payload }) => {
      state.options.filters.dateId = payload.id;
      state.options.filters.fromDate = payload.fromDate;
      state.options.filters.toDate = payload.toDate;
    })
    .addCase(setOptions, (state, { payload }) => {
      state.options = payload;
    })
    .addCase(setFilter, (state, { payload }) => {
      state.options.filters.filter = payload;
    });
});
