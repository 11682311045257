import React, { useRef } from 'react';
import { Control, useController } from 'react-hook-form';
import { HelperText, TextInput } from 'react-native-paper';
import { IconSource } from 'react-native-paper/lib/typescript/components/Icon';
import { RenderProps } from 'react-native-paper/lib/typescript/components/TextInput/types';
import MaskInput, { Mask } from 'react-native-mask-input';

import styles from './styles';

type Props = {
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters' | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control | any;
  editable?: boolean;
  errorMessage?: string;
  label: string;
  leftIcon?: IconSource;
  leftIconOnPress?: () => void;
  mask?: Mask;
  maxLength?: number;
  mode?: 'flat' | 'outlined';
  multiline?: boolean;
  name?: string;
  onChangeText?: (text: string) => void;
  onPress?: () => void;
  placeholder?: string;
  rightIcon?: IconSource;
  rightIconOnPress?: () => void;
  secureTextEntry?: boolean;
  value?: string;
};

export default function Input({
  autoCapitalize = 'none',
  control,
  editable = true,
  errorMessage = '',
  label,
  leftIcon,
  leftIconOnPress,
  mask,
  maxLength,
  mode = 'outlined',
  multiline = false,
  name = '',
  onChangeText,
  onPress,
  placeholder,
  rightIcon,
  rightIconOnPress,
  secureTextEntry,
  value,
}: Props) {
  const inputStyles = styles;
  const inputRef = useRef(null);

  let controlOnChange: (text: string) => void;
  if (control) {
    const { field, fieldState } = useController({ control, defaultValue: '', name });
    controlOnChange = field.onChange;
    value = field.value;
    errorMessage = fieldState.error?.message || '';
  }

  const handleOnChangeText = (text: string) => {
    controlOnChange && controlOnChange(text);
    onChangeText && onChangeText(text);
  };

  const handleOnPress = () => {
    onPress && onPress();
  };

  const handleLeftIconPress = () => {
    leftIconOnPress && leftIconOnPress();
  };

  const handleRightIconPress = () => {
    rightIconOnPress && rightIconOnPress();
  };

  const renderMaskedInput = (props: RenderProps) => {
    return <MaskInput {...props} mask={mask} />;
  };

  return (
    <>
      <TextInput
        autoCapitalize={autoCapitalize}
        editable={editable}
        error={!!errorMessage}
        label={label}
        left={!!leftIcon && <TextInput.Icon icon={leftIcon} onPress={handleLeftIconPress} />}
        maxLength={maxLength}
        mode={mode}
        multiline={multiline}
        onChangeText={handleOnChangeText}
        onPressIn={handleOnPress}
        placeholder={placeholder}
        pointerEvents={editable ? 'auto' : 'none'}
        ref={inputRef}
        render={mask ? renderMaskedInput : undefined}
        right={!!rightIcon && <TextInput.Icon icon={rightIcon} onPress={handleRightIconPress} />}
        secureTextEntry={secureTextEntry}
        style={inputStyles.input}
        value={value}
      />
      {!!errorMessage && <HelperText type="error">{errorMessage}</HelperText>}
    </>
  );
}
