import api from './api';

import {
  AddCoordinatorParams,
  Coordinator,
  PendingOrdersParams,
  PendingOrdersResult,
} from '../types/coordinator';
import { getCompanyId } from '../utils/auth';

export const addCoordinator = async (params: AddCoordinatorParams) => {
  return api.post<Coordinator>('/api/masters/create-coordinator', {
    data: {
      ...params,
      company: getCompanyId(),
    },
  });
};

export const getCoordinators = async () => {
  return api.get<Coordinator[]>('/api/coordinators');
};

export const listPendingOrders = async (params: PendingOrdersParams = {}) => {
  const { filters, orderBy = 'date:desc' } = params;
  let url = `/api/coordinator/list-peding-orders?sort=${orderBy}`;
  if (filters) {
    url += `&${filters}`;
  }

  return api.get<PendingOrdersResult>(url);
};

export const approveServiceOrder = async (id: number) => {
  return api.patch<string>(`/api/coordinator/approve-service-order/${id}`);
};

export const reproveServiceOrder = async (id: number) => {
  return api.patch<string>(`/api/coordinator/reprove-service-order/${id}`);
};
