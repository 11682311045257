import { createAsyncThunk } from '@reduxjs/toolkit';

import * as api from '../../services/consultant';
import { setError } from './error';
import { normalizeErrorMessage, showToast } from '../../utils';
import { AddConsultantParams } from '../../types/consultant';
import { goBack } from '../../utils/navigation';

export const addConsultant = createAsyncThunk(
  'consultant/addConsultant',
  async (params: AddConsultantParams, { dispatch }) => {
    try {
      const { data } = await api.addConsultant(params);
      showToast('Consultor cadastrado com sucesso');
      goBack();
      dispatch(getConsultants());
      return data;
    } catch (error) {
      dispatch(setError(normalizeErrorMessage(error)));
    }
  }
);

export const updateConsultant = createAsyncThunk(
  'consultant/updateConsultant',
  async ({ id, params }: { id: number; params: AddConsultantParams }, { dispatch }) => {
    try {
      const { data } = await api.updateConsultant(id, params);
      showToast('Consultor editado com sucesso');
      goBack();
      dispatch(getConsultants());
      return data;
    } catch (error) {
      dispatch(setError(normalizeErrorMessage(error)));
    }
  }
);

export const getConsultants = createAsyncThunk(
  'consultant/getConsultants',
  async (_, { dispatch }) => {
    try {
      const { data } = await api.getConsultants();
      return data;
    } catch (error) {
      dispatch(setError(normalizeErrorMessage(error)));
    }
  }
);
