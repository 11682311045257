import React, { ReactNode } from 'react';
import { Dialog as PaperDialog, Portal, Text } from 'react-native-paper';

import Button from '../button';
import styles from './styles';

type Props = {
  children?: ReactNode;
  confirmText?: string;
  dismissable?: boolean;
  message?: string;
  title?: string;
  visible: boolean;
  onConfirm?: () => void;
  onDismiss?: () => void;
};

export default function Dialog({
  children,
  confirmText,
  dismissable = true,
  message,
  title,
  visible,
  onConfirm,
  onDismiss,
}: Props) {
  const handleOnDismiss = () => {
    dismissable && onDismiss && onDismiss();
  };

  const handleOnConfirm = () => {
    onConfirm && onConfirm();
  };

  return (
    <Portal>
      <PaperDialog
        dismissable={dismissable}
        onDismiss={handleOnDismiss}
        style={styles.dialog}
        visible={visible}>
        <PaperDialog.Title>{title || 'Atenção'}</PaperDialog.Title>
        <PaperDialog.Content>
          {!!message && <Text variant="bodyMedium">{message}</Text>}
          {!!children && children}
        </PaperDialog.Content>
        <PaperDialog.Actions>
          <Button onPress={handleOnConfirm}>{confirmText || 'Fechar'}</Button>
        </PaperDialog.Actions>
      </PaperDialog>
    </Portal>
  );
}
