import api from './api';

import { GetProjectsParams, GetProjectsResponse, ProjectWithRelations } from '../types/project';
import { getCompanyId } from '../utils/auth';

export const getProjects = async (params: GetProjectsParams = {}) => {
  const { filters, orderBy = 'title', page = 1 } = params;
  let url = `/api/projects?populate=*&pagination[page]=${page}`;
  if (orderBy) {
    url += `&sort=${orderBy}`;
  }
  if (filters) {
    url += `&${filters}`;
  }
  return api.get<GetProjectsResponse>(url);
};

export const addProject = async (project: ProjectWithRelations) => {
  const companyId = getCompanyId();
  return api.post('/api/projects', {
    data: {
      ...project,
      company: [companyId],
      customer: [project.customerId],
      coordinator: project.coordinatorId,
    },
  });
};

export const editProject = async (id: number, project: ProjectWithRelations) => {
  return api.put(`/api/projects/${id}`, { data: { ...project, customer: [project.customerId] } });
};

export const deleteProject = async (id: number) => {
  return api.delete(`/api/projects/${id}`);
};
