import { Platform, StyleSheet } from 'react-native';
import { window } from '../../theme/tokens';

const styles = StyleSheet.create({
  toast: {
    ...(Platform.OS === 'web' ? { alignSelf: 'center', width: window.width / 3 } : undefined),
  },
});

export default styles;
