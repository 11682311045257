import React from 'react';
import { DatePickerModal } from 'react-native-paper-dates';
import { CalendarDate } from 'react-native-paper-dates/lib/typescript/Date/Calendar';

type Props = {
  value?: Date;
  visible: boolean;
  onClose: () => void;
  onSelect: (date: Date) => void;
};

export default function DatePicker({ value, visible, onClose, onSelect }: Props) {
  const date = value || new Date();

  const handleOnClose = () => {
    onClose && onClose();
  };

  const handleOnSelect = ({ date }: { date: CalendarDate }) => {
    if (date) {
      const selectedDate = new Date(date);
      onSelect && onSelect(selectedDate);
    }
  };

  return (
    <DatePickerModal
      date={date}
      locale="pt"
      mode="single"
      onConfirm={handleOnSelect}
      onDismiss={handleOnClose}
      visible={visible}
    />
  );
}
