import { createReducer } from '@reduxjs/toolkit';

import { Coordinator, PendingOrdersResult } from '../../types/coordinator';
import {
  getCoordinators,
  listPendingOrders,
  setPendingOrdersDateFilters,
  setPendingOrdersFilter,
  setPendingOrdersOptions,
} from '../actions/coordinator';
import { DEFAULT_PAGINATION_DATA } from '../../utils';
import { PageOptions } from '../../types/service-order';

const INITIAL_STATE = {
  loading: false,
  list: [] as Coordinator[],
  loadingPendingOrders: false,
  pagination: DEFAULT_PAGINATION_DATA, // TODO: vai usar?
  pendingOrders: [] as PendingOrdersResult,
  pendingOrdersOptions: {
    filters: {
      filter: '',
      status: 'PENDING',
      dateId: undefined,
      fromDate: undefined,
      toDate: undefined,
    },
    orderBy: 'date:desc',
  } as PageOptions,
};

export const reducer = createReducer(INITIAL_STATE, builder => {
  builder
    .addCase(getCoordinators.pending, state => {
      state.loading = true;
    })
    .addCase(getCoordinators.rejected, state => {
      state.loading = false;
    })
    .addCase(getCoordinators.fulfilled, (state, { payload }) => {
      state.list = payload || [];
      state.loading = false;
    })
    .addCase(listPendingOrders.pending, state => {
      state.loadingPendingOrders = true;
    })
    .addCase(listPendingOrders.rejected, state => {
      state.loadingPendingOrders = false;
    })
    .addCase(listPendingOrders.fulfilled, (state, { payload }) => {
      state.pendingOrders = payload || [];
      state.loadingPendingOrders = false;
    })
    .addCase(setPendingOrdersDateFilters, (state, { payload }) => {
      state.pendingOrdersOptions.filters.dateId = payload.id;
      state.pendingOrdersOptions.filters.fromDate = payload.fromDate;
      state.pendingOrdersOptions.filters.toDate = payload.toDate;
    })
    .addCase(setPendingOrdersOptions, (state, { payload }) => {
      state.pendingOrdersOptions = payload;
    })
    .addCase(setPendingOrdersFilter, (state, { payload }) => {
      state.pendingOrdersOptions.filters.filter = payload;
    });
});
