// Component abstracted from 'react-native-paper-dropdown'

import { LayoutChangeEvent, ScrollView, TextStyle, View, ViewStyle } from 'react-native';
import {
  Checkbox,
  Divider,
  MD3Theme,
  Menu,
  TextInput,
  TextInputProps,
  TouchableRipple,
  useTheme,
} from 'react-native-paper';
import React, { ReactNode, forwardRef, useEffect, useState, useCallback, Fragment } from 'react';
import styles from './styles';

type Without<T, K> = Pick<T, Exclude<keyof T, K>>;

type Props = {
  accessibilityLabel?: string;
  activeColor?: string;
  disabled?: boolean;
  dropDownContainerHeight?: number;
  dropDownContainerMaxHeight?: number;
  dropDownItemSelectedStyle?: ViewStyle;
  dropDownItemSelectedTextStyle?: TextStyle;
  dropDownItemStyle?: ViewStyle;
  dropDownItemTextStyle?: TextStyle;
  dropDownStyle?: ViewStyle;
  inputProps?: TextInputPropsWithoutTheme;
  label?: string | undefined;
  leftIcon?: string;
  list: Array<{
    custom?: ReactNode;
    label: string;
    value: string | number;
  }>;
  mode?: 'outlined' | 'flat' | undefined;
  multiSelect?: boolean;
  onDismiss: () => void;
  placeholder?: string | undefined;
  setValue: (_value: any) => void;
  showDropDown: () => void;
  theme?: MD3Theme;
  value: any;
  visible: boolean;
};

type TextInputPropsWithoutTheme = Without<TextInputProps, 'theme'>;

const DropDown = forwardRef<View, Props>((props, ref) => {
  const activeTheme = useTheme();
  const {
    accessibilityLabel,
    activeColor,
    disabled,
    dropDownContainerHeight,
    dropDownContainerMaxHeight,
    dropDownItemSelectedStyle,
    dropDownItemSelectedTextStyle,
    dropDownItemStyle,
    dropDownItemTextStyle,
    dropDownStyle,
    inputProps,
    label,
    leftIcon,
    list,
    mode = 'outlined',
    onDismiss,
    placeholder,
    setValue,
    showDropDown,
    theme,
    value,
    visible,
    multiSelect = false,
  } = props;
  const [displayValue, setDisplayValue] = useState('');
  const [inputLayout, setInputLayout] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  });

  const onLayout = (event: LayoutChangeEvent) => {
    setInputLayout(event.nativeEvent.layout);
  };

  useEffect(() => {
    if (multiSelect) {
      const _labels = list
        .filter(_ => value.indexOf(_.value) !== -1)
        .map(_ => _.label)
        .join(', ');
      setDisplayValue(_labels);
    } else {
      const _label = list.find(_ => _.value === value)?.label;
      if (_label) {
        setDisplayValue(_label);
      }
    }
  }, [list, value]);

  const isActive = useCallback(
    (currentValue: any) => {
      if (multiSelect) {
        return value.indexOf(currentValue) !== -1;
      } else {
        return value === currentValue;
      }
    },
    [value]
  );

  const setActive = useCallback(
    (currentValue: any) => {
      if (multiSelect) {
        const valueIndex = value.indexOf(currentValue);
        const values = value.split(',');
        if (valueIndex === -1) {
          setValue([...values, currentValue].join(','));
        } else {
          setValue([...values].filter(value => value !== currentValue).join(','));
        }
      } else {
        setValue(currentValue);
      }
    },
    [value]
  );

  return (
    <Menu
      visible={visible}
      onDismiss={onDismiss}
      theme={theme}
      anchor={
        <TouchableRipple
          disabled={disabled}
          ref={ref}
          style={styles.button}
          onPress={showDropDown}
          onLayout={onLayout}
          accessibilityLabel={accessibilityLabel}>
          <View pointerEvents={'none'}>
            <TextInput
              value={displayValue}
              mode={mode}
              label={label}
              left={!!leftIcon && <TextInput.Icon icon={leftIcon} />}
              placeholder={placeholder}
              pointerEvents={'none'}
              theme={theme}
              right={<TextInput.Icon icon={visible ? 'menu-up' : 'menu-down'} />}
              {...inputProps}
            />
          </View>
        </TouchableRipple>
      }
      style={{
        maxWidth: inputLayout?.width,
        width: inputLayout?.width,
        marginTop: inputLayout?.height,
        ...dropDownStyle,
      }}>
      <ScrollView
        bounces={false}
        style={{
          ...(dropDownContainerHeight
            ? {
                height: dropDownContainerHeight,
              }
            : {
                maxHeight: dropDownContainerMaxHeight || 200,
              }),
        }}>
        {list.map(_item => (
          <Fragment key={_item.value}>
            <TouchableRipple
              style={styles.optionButton}
              onPress={() => {
                setActive(_item.value);
                if (onDismiss) {
                  onDismiss();
                }
              }}>
              <Fragment>
                <Menu.Item
                  titleStyle={{
                    color: isActive(_item.value)
                      ? activeColor || (theme || activeTheme).colors.primary
                      : (theme || activeTheme).colors.secondary,
                    ...(isActive(_item.value)
                      ? dropDownItemSelectedTextStyle
                      : dropDownItemTextStyle),
                  }}
                  title={_item.custom || _item.label}
                  style={{
                    ...styles.menuItem,
                    maxWidth: inputLayout?.width,
                    ...(isActive(_item.value) ? dropDownItemSelectedStyle : dropDownItemStyle),
                  }}
                />
                {multiSelect && (
                  <Checkbox.Android
                    theme={{
                      colors: { accent: activeTheme?.colors.primary },
                    }}
                    status={isActive(_item.value) ? 'checked' : 'unchecked'}
                    onPress={() => setActive(_item.value)}
                  />
                )}
              </Fragment>
            </TouchableRipple>
            <Divider />
          </Fragment>
        ))}
      </ScrollView>
    </Menu>
  );
});

DropDown.displayName = 'DropDown';
export default DropDown;
