import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createStackNavigator } from '@react-navigation/stack';
import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import AccountProfile from '../pages/account/profile';
import ChangePassword from '../pages/account/change-password';
import NewCustomer from '../pages/customer/new';
import CustomerDetails from '../pages/customer/details';
import CustomerHome from '../pages/customer/home';
import InvoicesHome from '../pages/invoices/home';
import NewInvoice from '../pages/invoices/new';
import InvoiceDetails from '../pages/invoices/details';
import RecordsHome from '../pages/records-home';
import PendingOrdersHome from '../pages/pending-orders/home';
import ProjectHome from '../pages/project/home';
import NewProject from '../pages/project/new';
import ProjectDetails from '../pages/project/details';
import NewServiceOrder from '../pages/service-order/new';
import ServiceOrderDetails from '../pages/service-order/details';
import ServiceOrderHome from '../pages/service-order/home';
import ConsultantsHome from '../pages/consultant/home';
import NewConsultant from '../pages/consultant/new';
import ConsultantDetails from '../pages/consultant/details';
import CoordinatorsHome from '../pages/coordinator/home';
import NewCoordinator from '../pages/coordinator/new';
import CoordinatorDetails from '../pages/coordinator/details';

import { CustomerData } from '../types/customer';
import { Project } from '../types/project';
import { useAppDispatch } from '../store';
import { verifyFirstLogin } from '../store/actions/auth';
import { isCoordinatorSelector, isMasterSelector } from '../store/selectors/auth';
import { Consultant } from '../types/consultant';
import { Coordinator } from '../types/coordinator';
import { InvoiceData } from '../types/invoice';

export type UserTabParamList = {
  Account: undefined;
  Invoices: undefined;
  PendingOrdersHome: undefined;
  RecordsHome: undefined;
  ServiceOrderHome: undefined;
};

export type UserStackParamList = {
  Home: undefined;
  ChangePassword: undefined;
  CustomerHome: undefined;
  CustomerDetails: CustomerData;
  NewInvoice: undefined;
  InvoiceDetails: {
    invoice: InvoiceData;
  };
  NewCustomer: undefined;
  NewProject: undefined;
  NewServiceOrder: undefined;
  ProjectHome: undefined;
  ProjectDetails: Project;
  ServiceOrderDetails: { id: number };
  NewConsultant: undefined;
  ConsultantsHome: undefined;
  ConsultantDetails: {
    user: Consultant;
  };
  NewCoordinator: undefined;
  CoordinatorsHome: undefined;
  CoordinatorDetails: {
    user: Coordinator;
  };
};

const Tab = createMaterialBottomTabNavigator<UserTabParamList>();

const Stack = createStackNavigator<UserStackParamList>();

const TabRoutes = () => {
  const isCoordinator = useSelector(isCoordinatorSelector);
  const isMaster = useSelector(isMasterSelector);

  return (
    <Tab.Navigator initialRouteName="ServiceOrderHome">
      {!isCoordinator && (
        <Tab.Screen
          name="ServiceOrderHome"
          component={ServiceOrderHome}
          options={{
            tabBarLabel: 'Home',
            tabBarIcon: ({ color }) => (
              <MaterialCommunityIcons name="home" color={color} size={26} />
            ),
            title: 'Ordens de serviço',
          }}
        />
      )}
      {isCoordinator && (
        <Tab.Screen
          name="PendingOrdersHome"
          component={PendingOrdersHome}
          options={{
            tabBarLabel: 'Home',
            tabBarIcon: ({ color }) => (
              <MaterialCommunityIcons name="home" color={color} size={26} />
            ),
            title: 'Aprovações pendentes',
          }}
        />
      )}

      {isMaster && (
        <Tab.Screen
          name="Invoices"
          component={InvoicesHome}
          options={{
            tabBarLabel: 'Faturas',
            tabBarIcon: ({ color }) => (
              <MaterialCommunityIcons name="file-document-outline" color={color} size={26} />
            ),
            title: 'Faturas',
          }}
        />
      )}

      <Tab.Screen
        name="RecordsHome"
        component={RecordsHome}
        options={{
          tabBarLabel: 'Cadastros',
          tabBarIcon: ({ color }) => (
            <MaterialCommunityIcons name="file-document-edit" color={color} size={26} />
          ),
          title: 'Cadastros',
        }}
      />

      <Tab.Screen
        name="Account"
        component={AccountProfile}
        options={{
          tabBarLabel: 'Perfil',
          tabBarIcon: ({ color }) => (
            <MaterialCommunityIcons name="account" color={color} size={26} />
          ),
          title: 'Perfil',
        }}
      />
    </Tab.Navigator>
  );
};

export default function AuthRoutes() {
  const dispatch = useAppDispatch();
  const isMaster = useSelector(isMasterSelector);

  useEffect(() => {
    dispatch(verifyFirstLogin());
  }, []);

  return (
    <Stack.Navigator
      initialRouteName="Home"
      screenOptions={{
        animationEnabled: false,
        headerBackTitle: 'Voltar',
        headerShown: false,
      }}>
      <Stack.Screen name="Home" component={TabRoutes} />
      <Stack.Screen
        name="ChangePassword"
        component={ChangePassword}
        options={{ headerShown: true, title: 'Alterar Senha' }}
      />
      <Stack.Screen
        name="CustomerHome"
        component={CustomerHome}
        options={{ headerShown: true, title: 'Clientes' }}
      />
      <Stack.Screen
        name="NewCustomer"
        component={NewCustomer}
        options={{ headerShown: true, title: 'Cadastrar Cliente' }}
      />
      <Stack.Screen
        name="CustomerDetails"
        component={CustomerDetails}
        options={{ headerShown: true, title: 'Detalhes do Cliente' }}
      />
      <Stack.Screen
        name="ProjectHome"
        component={ProjectHome}
        options={{ headerShown: true, title: 'Projetos' }}
      />
      <Stack.Screen
        name="NewProject"
        component={NewProject}
        options={{ headerShown: true, title: 'Cadastrar Projeto' }}
      />
      <Stack.Screen
        name="NewInvoice"
        component={NewInvoice}
        options={{ headerShown: true, title: 'Gerar Fatura' }}
      />
      <Stack.Screen
        name="InvoiceDetails"
        component={InvoiceDetails}
        options={{ headerShown: true, title: 'Detalhes da Fatura' }}
      />
      <Stack.Screen
        name="ProjectDetails"
        component={ProjectDetails}
        options={{ headerShown: true, title: 'Detalhes do Projeto' }}
      />
      <Stack.Screen
        name="NewServiceOrder"
        component={NewServiceOrder}
        options={{ headerShown: true, title: 'Cadastrar Ordem de Serviço' }}
      />
      <Stack.Screen
        name="ServiceOrderDetails"
        component={ServiceOrderDetails}
        options={{ headerShown: true, title: 'Detalhes da Ordem de Serviço' }}
      />
      <Stack.Screen
        name="NewConsultant"
        component={NewConsultant}
        options={{ headerShown: true, title: 'Cadastrar Consultor' }}
      />
      {isMaster && (
        <Stack.Screen
          name="ConsultantsHome"
          component={ConsultantsHome}
          options={{ headerShown: true, title: 'Consultores' }}
        />
      )}
      <Stack.Screen
        name="ConsultantDetails"
        component={ConsultantDetails}
        options={{ headerShown: true, title: 'Detalhes do Consultor' }}
      />
      <Stack.Screen
        name="NewCoordinator"
        component={NewCoordinator}
        options={{ headerShown: true, title: 'Cadastrar Coordenador' }}
      />
      {isMaster && (
        <Stack.Screen
          name="CoordinatorsHome"
          component={CoordinatorsHome}
          options={{ headerShown: true, title: 'Coordenadores' }}
        />
      )}
      <Stack.Screen
        name="CoordinatorDetails"
        component={CoordinatorDetails}
        options={{ headerShown: true, title: 'Detalhes do Coordenador' }}
      />
    </Stack.Navigator>
  );
}
