import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Text, useTheme } from 'react-native-paper';
import { Masks } from 'react-native-mask-input';
import { StackScreenProps } from '@react-navigation/stack';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Button, Container, Dialog, Input, Spacer } from '../../../components';
import { AuthStackParamList } from '../../../routes/auth';
import { useAppDispatch } from '../../../store';
import styles from './styles';
import { register } from '../../../store/actions/auth';
import { isValidCNPJ } from '@brazilian-utils/brazilian-utils';
import { RegisterResponse } from '../../../types/auth';

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required('Preencha o nome fantasia')
    .min(3, 'O nome fantasia deve ter no mínimo 3 dígitos'),
  cnpj: yup
    .string()
    .test('invalid-cnpj', 'Digite um CNPJ válido', value => isValidCNPJ(value || '')),
  email: yup.string().required('Preencha o e-mail').email('Digite um endereço de e-mail válido'),
  username: yup
    .string()
    .required('Preencha o nome de usuário')
    .min(3, 'O nome de usuário deve ter no mínimo 3 dígitos'),
});

type FieldValues = {
  cpf: string;
  cnpj: string;
  email: string;
  name: string;
  notes: string;
  username: string;
};

type Props = StackScreenProps<AuthStackParamList, 'SignUp'>;

export default function SignUp({ navigation }: Props) {
  const { handleSubmit, control } = useForm<FieldValues>({
    resolver: yupResolver(validationSchema),
  });
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const signUpStyles = styles(theme);
  const [loading, setLoading] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  const goToLogin = () => {
    navigation.goBack();
  };

  const handleOnSuccessConfirm = () => {
    setShowSuccessDialog(false);
    goToLogin();
  };

  const onSubmit = async (data: FieldValues) => {
    setLoading(true);
    const { payload } = await dispatch(
      register({
        ...data,
        cpf: '',
        notes: '',
      })
    );
    const response = payload as RegisterResponse;
    if (response?.id) {
      setShowSuccessDialog(true);
    }
    setLoading(false);
  };

  return (
    <Container
      flexGrow
      safe
      spacingHorizontal="medium"
      style={signUpStyles.container}
      spacingVertical="medium"
      webSmall>
      <Input name="name" control={control} label="Nome Fantasia" leftIcon="account" />
      <Input name="cnpj" control={control} label="CNPJ" leftIcon="account" mask={Masks.BRL_CNPJ} />
      <Input name="email" control={control} label="E-mail" leftIcon="email" />
      <Input name="username" control={control} label="Usuário" leftIcon="account" />
      <Spacer size="medium" />
      <Button loading={loading} onPress={handleSubmit(onSubmit)}>
        Registrar
      </Button>
      <Spacer size="medium" />
      <Text variant="bodyMedium" style={signUpStyles.loginText}>
        Já possui conta?{' '}
        <Text onPress={goToLogin} style={signUpStyles.goToLoginText}>
          Entre aqui
        </Text>
      </Text>
      <Dialog
        message="Conta cadastrada com sucesso, você receberá em instantes um código de acesso por e-mail."
        onDismiss={handleOnSuccessConfirm}
        onConfirm={handleOnSuccessConfirm}
        visible={showSuccessDialog}
      />
    </Container>
  );
}
