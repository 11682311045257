import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Button, Input, Spacer } from '../../../../components';
import { AddCoordinatorParams } from '../../../../types/coordinator';

const validationSchema = yup.object().shape({
  name: yup.string().required('Preencha o nome').min(3, 'O nome deve ter no mínimo 3 dígitos'),
  email: yup.string().required('Preencha o e-mail').email('Digite um endereço de e-mail válido'),
  username: yup
    .string()
    .required('Preencha o nome de usuário')
    .min(3, 'O nome de usuário deve ter no mínimo 3 dígitos'),
});

type Props = {
  disabled?: boolean;
  initialValues?: Partial<AddCoordinatorParams>;
  loading?: boolean;
  onSubmit?: (data: AddCoordinatorParams) => void;
};
export default function UserForm({ disabled, initialValues, loading, onSubmit }: Props) {
  const { handleSubmit, control } = useForm<AddCoordinatorParams>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  return (
    <>
      <Input name="name" control={control} editable={!disabled} label="Nome" leftIcon="account" />
      <Input name="email" control={control} editable={!disabled} label="E-mail" leftIcon="email" />
      <Input
        name="username"
        control={control}
        editable={!disabled}
        label="Usuário"
        leftIcon="account"
      />
      <Spacer size="medium" />
      {!!onSubmit && (
        <Button loading={loading} onPress={handleSubmit(onSubmit)}>
          Salvar
        </Button>
      )}
    </>
  );
}
