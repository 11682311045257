import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { List } from 'react-native-paper';

type Props = {
  description?: React.ReactNode;
  disabled?: boolean;
  leftIcon?: string;
  leftIconColor?: string;
  onPress?: () => void;
  rightIcon?: string;
  style?: StyleProp<ViewStyle>;
  title?: React.ReactNode;
};

export default function ListItem({
  description,
  disabled,
  leftIcon,
  leftIconColor,
  onPress,
  rightIcon,
  style,
  title,
}: Props) {
  const handleOnPress = () => {
    onPress && onPress();
  };

  return (
    <List.Item
      description={description}
      disabled={disabled}
      left={({ color }) => leftIcon && <List.Icon color={leftIconColor ?? color} icon={leftIcon} />}
      onPress={handleOnPress}
      right={({ color }) => rightIcon && <List.Icon color={color} icon={rightIcon} />}
      style={style}
      title={title}
    />
  );
}
