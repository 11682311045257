import { Platform, StyleSheet } from 'react-native';
import { MD3Theme } from 'react-native-paper';

import { spacing, window } from '../../theme/tokens';

type Props = {
  flexGrow: boolean;
  flexRow: boolean;
  gap?: 'xxsmall' | 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
  spacingHorizontal?: 'xxsmall' | 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
  spacingVertical?: 'xxsmall' | 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
  theme: MD3Theme;
  webSmall?: boolean;
};

const styles = ({
  flexGrow,
  flexRow,
  gap,
  spacingHorizontal,
  spacingVertical,
  theme,
  webSmall,
}: Props) => {
  return StyleSheet.create({
    container: {
      backgroundColor: theme.colors.background,
      ...(flexGrow ? { flex: 1 } : undefined),
      ...(flexRow ? { flexDirection: 'row' } : undefined),
      ...(gap ? { gap: spacing[gap] } : undefined),
      ...(spacingHorizontal ? { paddingHorizontal: spacing[spacingHorizontal] } : undefined),
      ...(spacingVertical ? { paddingVertical: spacing[spacingVertical] } : undefined),
      ...(webSmall && Platform.OS === 'web'
        ? {
            alignSelf: 'center',
            width: window.width / 3,
          }
        : undefined),
    },
  });
};

export default styles;
