import { Platform, StyleSheet } from 'react-native';
import { window } from '../../../theme/tokens';

const styles = StyleSheet.create({
  container: {
    width: Platform.OS === 'web' ? window.width / 2 : undefined,
  },
});

export default styles;
