import React, { useState } from 'react';

import { Container } from '../../../components';
import { useAppDispatch } from '../../../store';
import styles from './styles';
import InvoiceForm from '../components/invoice-form';
import { AddInvoiceParams } from '../../../types/invoice';
import { addInvoice } from '../../../store/actions/invoice';

export default function NewInvoice() {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: AddInvoiceParams) => {
    setLoading(true);
    await dispatch(addInvoice(data));
    setLoading(false);
  };

  return (
    <Container
      flexGrow
      spacingHorizontal="medium"
      spacingVertical="medium"
      style={styles.container}>
      <InvoiceForm loading={loading} onSubmit={onSubmit} />
    </Container>
  );
}
