import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import * as api from '../../services/auth';
import { ChangePasswordParams, LoginParams, RegisterParams } from '../../types/auth';
import { setError } from './error';
import { normalizeErrorMessage } from '../../utils';
import { navigate } from '../../utils/navigation';

export const register = createAsyncThunk(
  'auth/register',
  async (params: RegisterParams, { dispatch }) => {
    try {
      const { data } = await api.register(params);
      return data;
    } catch (error) {
      dispatch(setError(normalizeErrorMessage(error)));
    }
  }
);

export const login = createAsyncThunk('auth/login', async (params: LoginParams, { dispatch }) => {
  try {
    const { data } = await api.login(params);
    return data;
  } catch (error) {
    dispatch(setError(normalizeErrorMessage(error)));
  }
});

export const verifyFirstLogin = createAsyncThunk(
  'auth/verifyFirstLogin',
  async (_, { dispatch }) => {
    try {
      const { data } = await api.verifyFirstLogin();
      if (data.firstAccess) {
        navigate('ChangePassword');
      }
      return data;
    } catch (error) {
      dispatch(setError(normalizeErrorMessage(error)));
    }
  }
);

export const recoverAccount = createAsyncThunk(
  'auth/recoverAccount',
  async (email: string, { dispatch }) => {
    try {
      const { data } = await api.recoverAccount(email);
      return data;
    } catch (error) {
      dispatch(setError(normalizeErrorMessage(error)));
    }
  }
);

export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async (params: ChangePasswordParams, { dispatch }) => {
    try {
      const { data } = await api.changePassword(params);
      return data;
    } catch (error) {
      dispatch(setError(normalizeErrorMessage(error)));
    }
  }
);

export const logout = createAction('auth/logout');

export const toggleDarkMode = createAction('auth/toggleDarkMode');
