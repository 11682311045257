import { StyleSheet } from 'react-native';
import { MD3Theme } from 'react-native-paper';
import { spacing } from '../../../theme/tokens';
import { mainBackgroundColor, white } from '../../../theme/colors';

const styles = (theme: MD3Theme) => {
  return StyleSheet.create({
    backgroundPurple: {
      backgroundColor: mainBackgroundColor,
    },
    container: {
      backgroundColor: mainBackgroundColor,
      flex: 1,
      justifyContent: 'center',
    },
    forgotPasswordText: { alignSelf: 'flex-end' },
    linkText: { color: theme.colors.primary },
    logo: { height: 70, width: '100%' },
    signupText: { alignSelf: 'center', color: white },
    versionText: { alignSelf: 'center', color: white, marginTop: spacing.large },
  });
};

export default styles;
