import React, { useState } from 'react';

import { Container } from '../../../components';
import { Customer } from '../../../types/customer';
import { useAppDispatch } from '../../../store';
import { addCustomer } from '../../../store/actions/customer';
import CustomerForm from '../components/customer-form';
import styles from './styles';

export default function NewCustomer() {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: Customer) => {
    setLoading(true);
    await dispatch(addCustomer(data));
    setLoading(false);
  };

  return (
    <Container
      flexGrow
      spacingHorizontal="medium"
      spacingVertical="medium"
      style={styles.container}>
      <CustomerForm loading={loading} onSubmit={onSubmit} />
    </Container>
  );
}
