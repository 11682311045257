import React, { useCallback, useState } from 'react';
import { FlatList, ListRenderItem, View } from 'react-native';
import { Checkbox, Divider, IconButton, List, Searchbar, Text, useTheme } from 'react-native-paper';
import { IconSource } from 'react-native-paper/lib/typescript/components/Icon';

import styles from './styles';
import BottomModal from '../bottom-modal';
import Loading from '../loading';
import Container from '../container';
import Button from '../button';
import { DateQuickFilters } from '..';
import { QuickDateResult } from '../date-quick-filters';

type Item = {
  backgroundColor?: string;
  description: string;
  id: number | string;
  icon?: IconSource;
} & any;

type Props = {
  hideSearchButton?: boolean;
  items: Item[];
  loading?: boolean;
  multiSelect?: boolean;
  onDismiss: () => void;
  onEndReached?: () => void;
  onEndReachedThreshold?: number;
  onFilter?: (value: string) => void;
  onSelect: (item: Item | Item[]) => void;
  onQuickDateSelect?: (item: QuickDateResult) => void;
  showQuickDate?: boolean;
  visible: boolean;
};

export default function SearchModal({
  hideSearchButton,
  items,
  loading,
  multiSelect = false,
  onDismiss,
  onEndReached,
  onEndReachedThreshold = 0.2,
  onFilter,
  onSelect,
  onQuickDateSelect,
  showQuickDate,
  visible,
}: Props) {
  const theme = useTheme();
  const [selectedItems, setSelectedItems] = useState([] as Item[]);
  const [filter, setFilter] = useState('');

  const handleSetFilter = (value: string) => {
    setFilter(value);
    onFilter && onFilter(value);
  };

  const handleOnEndReached = () => {
    onEndReached && onEndReached();
  };

  const handleOnSelect = (selectedItem: Item) => {
    if (multiSelect) {
      const newItems = [...selectedItems];
      const itemIndex = newItems.findIndex(item => item.id === selectedItem.id);
      if (itemIndex > -1) {
        newItems.splice(itemIndex, 1);
      } else {
        newItems.push(selectedItem);
      }
      setSelectedItems(newItems);
    } else {
      onSelect(selectedItem);
    }
  };

  const handleMultiSelectConfirm = () => {
    onSelect(selectedItems);
    setSelectedItems([] as Item[]);
  };

  const isChecked = useCallback(
    (id: number | string) => {
      return selectedItems.some(item => item.id === id);
    },
    [selectedItems]
  );

  const renderItem: ListRenderItem<Item> = ({ item }) => {
    return (
      <List.Item
        title={item.description}
        left={() => (
          <>
            {multiSelect && (
              <Checkbox.Android
                onPress={() => handleOnSelect(item)}
                status={isChecked(item.id) ? 'checked' : 'unchecked'}
                theme={{
                  colors: { accent: theme.colors.primary },
                }}
              />
            )}
            {!multiSelect && (
              <IconButton
                containerColor={item.backgroundColor}
                icon={item.icon || 'view-list'}
                iconColor="white"
                mode="contained"
                size={20}
                style={styles.itemIcon}
              />
            )}
          </>
        )}
        onPress={() => handleOnSelect(item)}
      />
    );
  };

  const renderEmptyComponent = () => {
    return (
      <View style={styles.emptyContainer}>
        <Text>Nenhum registro encontrado</Text>
      </View>
    );
  };

  return (
    <BottomModal visible={visible} onClose={onDismiss}>
      <View style={styles.container}>
        {!hideSearchButton && (
          <Searchbar onChangeText={handleSetFilter} placeholder="Pesquisar" value={filter} />
        )}
        {showQuickDate && onQuickDateSelect && (
          <DateQuickFilters style={styles.dateQuickFilter} onSelect={onQuickDateSelect} />
        )}
        <FlatList
          data={items}
          ItemSeparatorComponent={Divider}
          keyExtractor={item => `${item.id}_${item.description}`}
          ListEmptyComponent={renderEmptyComponent}
          onEndReached={handleOnEndReached}
          onEndReachedThreshold={onEndReachedThreshold}
          renderItem={renderItem}
        />
        {loading && (
          <Container spacingVertical="small">
            <Loading />
          </Container>
        )}
        {multiSelect && <Button onPress={handleMultiSelectConfirm}>Confirmar</Button>}
      </View>
    </BottomModal>
  );
}
