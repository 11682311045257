import api from './api';

import {
  GetServiceOrderResponse,
  GetServiceOrdersParams,
  GetServiceOrdersResponse,
  ServiceOrder,
  ServiceOrderWithRelations,
} from '../types/service-order';
import { getCompanyId } from '../utils/auth';

export const getServiceOrder = async (id: number) => {
  return api.get<GetServiceOrderResponse>(`/api/service-orders/${id}?populate=*`);
};

export const getServiceOrders = async (params: GetServiceOrdersParams = {}) => {
  const { filters, orderBy = 'date:desc', page = 1 } = params;
  let url = `/api/service-orders?populate=*&pagination[page]=${page}`;
  if (orderBy) {
    url += `&sort=${orderBy}`;
  }
  if (filters) {
    url += `&${filters}`;
  }

  return api.get<GetServiceOrdersResponse>(url);
};

export const addServiceOrder = async (serviceOrder: ServiceOrderWithRelations) => {
  const companyId = getCompanyId();
  return api.post<Partial<ServiceOrder>>('/api/service-orders', {
    data: {
      ...serviceOrder,
      company: [companyId],
      customer: [serviceOrder.customerId],
      project: [serviceOrder.projectId],
    },
  });
};

export const editServiceOrder = async (id: number, serviceOrder: ServiceOrderWithRelations) => {
  return api.put(`/api/service-orders/${id}`, {
    data: {
      ...serviceOrder,
      customer: [serviceOrder.customerId],
      project: [serviceOrder.projectId],
    },
  });
};

export const deleteServiceOrder = async (id: number) => {
  return api.delete(`/api/service-orders/${id}`);
};

export const sendByEmail = async (id: number, email: string) => {
  return api.post('/print-os/send-report-by-email', {
    data: {
      companyId: getCompanyId(),
      email,
      orderId: id,
    },
  });
};
