import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RadioButton, Searchbar, Text } from 'react-native-paper';

import { BottomModal, Button, Spacer } from '../../../../components';
import { customersOptionsSelector } from '../../../../store/selectors/customer';

type Filters = {
  filter: string;
};

type Props = {
  onConfirm: (data: { filters: Filters; orderBy: string }) => void;
  onDismiss: () => void;
  visible: boolean;
};

export default function FiltersModal({ onConfirm, onDismiss, visible }: Props) {
  const options = useSelector(customersOptionsSelector);
  const [filter, setFilter] = useState(options.filters.filter);
  const [orderBy, setOrderBy] = useState(options.orderBy || 'name');

  const handleOnClean = () => {
    setFilter('');
    setOrderBy('name');
    onConfirm &&
      onConfirm({
        filters: {
          filter: '',
        },
        orderBy: 'name',
      });
  };

  const handleOnConfirm = () => {
    onConfirm &&
      onConfirm({
        filters: { filter },
        orderBy,
      });
  };

  return (
    <BottomModal visible={visible} onClose={onDismiss}>
      <Searchbar onChangeText={setFilter} placeholder="Pesquisar" value={filter} />
      <Spacer />
      <Text variant="titleMedium">Ordenar por:</Text>
      <RadioButton.Group onValueChange={setOrderBy} value={orderBy}>
        <RadioButton.Item label="Nome crescente" value="name" />
        <RadioButton.Item label="Nome decrescente" value="name:desc" />
        <RadioButton.Item label="Data de criação crescente" value="createdAt" />
        <RadioButton.Item label="Data de criação decrescente" value="createdAt:desc" />
        <RadioButton.Item label="Data de edição crescente" value="updatedAt" />
        <RadioButton.Item label="Data de edição decrescente" value="updatedAt:desc" />
      </RadioButton.Group>
      <Spacer size="small" />
      <Button onPress={handleOnConfirm}>Filtrar</Button>
      <Button mode="text" onPress={handleOnClean}>
        Limpar filtros
      </Button>
    </BottomModal>
  );
}
