import React, { useEffect, useState } from 'react';
import { NativeModules, Platform } from 'react-native';
import { Divider, IconButton, Menu } from 'react-native-paper';
import { StackScreenProps } from '@react-navigation/stack';

import { ConfirmDialog, Container } from '../../../components';
import { useAppDispatch } from '../../../store';
import styles from './styles';
import InvoiceForm from '../components/invoice-form';
import { UserStackParamList } from '../../../routes/user';
import LoadingModal from '../../../components/loading-modal';
import { downloadInvoice } from '../../../services/invoice';
import { setError } from '../../../store/actions/error';
import { blobToDataUrl, normalizeErrorMessage, showToast } from '../../../utils';
import { deleteInvoice } from '../../../store/actions/invoice';

type Props = StackScreenProps<UserStackParamList, 'InvoiceDetails'>;

export default function InvoiceDetails({ navigation, route }: Props) {
  const dispatch = useAppDispatch();
  const { params } = route;
  const [loading, setLoading] = useState(false);
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);

  const downloadPdf = async () => {
    try {
      setLoading(true);
      const { data } = await downloadInvoice(params.invoice.id);
      if (Platform.OS === 'web') {
        window.open(URL.createObjectURL(data));
      } else {
        try {
          const { DocumentDirectoryPath, writeFile } = require('react-native-fs');
          const FileViewer = require('react-native-file-viewer');
          const pdfPath = `${DocumentDirectoryPath}/invoice_${params.invoice.id}.pdf`;
          const content = ((await blobToDataUrl(data)) as string)?.split(',')?.[1];

          await writeFile(pdfPath, content, 'base64');
          showToast(`Pdf salvo no diretório ${pdfPath}`);
          await FileViewer.open(pdfPath, { showOpenWithDialog: true, showAppsSuggestions: true });
        } catch (error) {
          dispatch(setError('Erro ao abrir PDF'));
        }
      }
    } catch (error) {
      dispatch(setError(normalizeErrorMessage(error)));
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteInvoice = () => {
    setShowConfirmAlert(false);
    dispatch(deleteInvoice(params.invoice.id));
  };

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => {
        const [showMenu, setShowMenu] = useState(false);
        return (
          <Menu
            visible={showMenu}
            onDismiss={() => setShowMenu(false)}
            anchor={<IconButton icon="dots-vertical" onPress={() => setShowMenu(true)} size={25} />}
            statusBarHeight={
              Platform.OS === 'android' ? NativeModules?.StatusBarManager?.HEIGHT : undefined
            }>
            <Menu.Item
              leadingIcon="download"
              onPress={() => {
                setShowMenu(false);
                downloadPdf();
              }}
              title="Download PDF"
            />
            <Divider />
            <Menu.Item
              leadingIcon="delete"
              onPress={() => {
                setShowMenu(false);
                setShowConfirmAlert(true);
              }}
              title="Excluir"
            />
          </Menu>
        );
      },
    });
  }, []);

  return (
    <Container
      flexGrow
      spacingHorizontal="medium"
      spacingVertical="medium"
      style={styles.container}>
      <InvoiceForm
        disabled
        initialValues={{
          customerName: params?.invoice?.customer?.name,
          serviceOrders: params?.invoice?.service_orders,
          project: params?.invoice?.project,
          projectId: params?.invoice?.project?.id,
          projectTitle: params?.invoice?.project?.title,
        }}
      />
      <LoadingModal title="Efetuando download..." visible={loading} />
      <ConfirmDialog
        message="Você deseja excluir a fatura?"
        onConfirm={handleDeleteInvoice}
        onDismiss={() => setShowConfirmAlert(false)}
        visible={showConfirmAlert}
      />
    </Container>
  );
}
