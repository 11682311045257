import React, { useEffect } from 'react';
import { Provider, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Platform, StatusBar } from 'react-native';
import { PaperProvider } from 'react-native-paper';
import SplashScreen from 'react-native-splash-screen';
import { NavigationContainer } from '@react-navigation/native';
import { pt, registerTranslation } from 'react-native-paper-dates';

registerTranslation('pt', pt);

import Routes from './routes';
import { Container, Dialog, Toast } from './components';
import { persistor, store, useAppDispatch } from './store';
import { isDarkModeSelector } from './store/selectors/auth';
import { errorSelector } from './store/selectors/error';
import { clearError } from './store/actions/error';
import { navigationRef } from './utils/navigation';
import toastUtil from './utils/toast';
import { darkTheme, lightTheme, normalizeNavigationTheme } from './theme';

const WebIconsImport = () => (
  // eslint-disable-next-line react-native/no-raw-text
  <style type="text/css">{`
@font-face {
  font-family: 'MaterialCommunityIcons';
  src: url(${require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf')}) format('truetype');
}
`}</style>
);

function App() {
  const isDarkMode = useSelector(isDarkModeSelector);
  const error = useSelector(errorSelector);
  const dispatch = useAppDispatch();

  const handleClearError = () => {
    dispatch(clearError());
  };

  useEffect(() => {
    Platform.OS !== 'web' && SplashScreen.hide();
  }, []);

  const theme = isDarkMode ? darkTheme : lightTheme;

  return (
    <NavigationContainer ref={navigationRef} theme={normalizeNavigationTheme(theme)}>
      <PaperProvider theme={theme}>
        <Container flexGrow>
          <StatusBar
            barStyle={isDarkMode ? 'light-content' : 'dark-content'}
            translucent
            backgroundColor="transparent"
          />
          {Platform.OS === 'web' && <WebIconsImport />}
          <Routes />
        </Container>
        <Dialog
          message={error.message}
          onDismiss={handleClearError}
          onConfirm={handleClearError}
          visible={error.visible}
        />
        <Toast ref={ref => toastUtil.setRef(ref)} />
      </PaperProvider>
    </NavigationContainer>
  );
}

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  );
};

export default AppWrapper;
