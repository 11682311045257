import React, { useState } from 'react';
import { Pressable, View } from 'react-native';
import { Divider, HelperText, IconButton, List, Text, useTheme } from 'react-native-paper';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  Button,
  Container,
  DatePicker,
  Input,
  ListItem,
  ProjectsModal,
  SearchModal,
  Spacer,
  TimePicker,
} from '../../../../components';
import {
  formatDateToISOString,
  formatISOStringToLocaleString,
  formatMinutesToTime,
  formatTimeToMinutes,
} from '../../../../utils';
import styles from './styles';
import { Activity } from '../../../../types/service-order';
import { Project, Task } from '../../../../types/project';
import { TimePickerResult } from '../../../../components/time-picker';

const validationSchema = yup.object().shape({
  start_time: yup.string().required('Selecione a hora de início'),
  end_time: yup.string().required('Selecione a hora fim'),
  projectTitle: yup.string().required('Selecione o projeto'),
  activities: yup
    .array()
    .of(
      yup.object().shape({
        title: yup.string().required('Preencha o título da atividade'),
        description: yup.string().required('Preencha a descrição da atividade'),
        duration_time: yup
          .string()
          .required('Preencha a duração da atividade')
          .min(5, 'Informe uma quantidade de horas válida'),
        taskDescription: yup.string().required('Selecione uma tarefa'),
      })
    )
    .min(1, 'Informe no mínimo uma atividade'),
});

export type FormData = {
  start_time: string;
  end_time: string;
  break: string;
  transfer: string;
  amount_time: string;
  date: string;
  project?: Project;
  projectId: number;
  projectTitle: string;
  customerId: number;
  customerName: string;
  activities: Activity[];
};

type Props = {
  initialValues?: Partial<FormData>;
  loading?: boolean;
  onApprove?: (approved: boolean) => void;
  onSubmit: (data: FormData) => void;
  showApproveOptions?: boolean;
  viewMode?: boolean;
};
export default function ServiceOrderForm({
  initialValues,
  loading,
  onApprove,
  onSubmit,
  showApproveOptions,
  viewMode,
}: Props) {
  const theme = useTheme();
  const { control, formState, getValues, handleSubmit, setValue } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });
  const activitiesArray = useFieldArray({ name: 'activities', control: control });
  const [showDateModal, setShowDateModal] = useState(false);
  const [showStartTimeModal, setShowStartTimeModal] = useState(false);
  const [showEndTimeModal, setShowEndTimeModal] = useState(false);
  const [showBreakModal, setShowBreakModal] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [tasksModal, setTasksModal] = useState({ visible: false, index: -1 });
  const [projectTasks, setProjectTasks] = useState<Task[]>(
    (initialValues?.project?.tasks as any) || []
  );

  const handleSelectDate = (date: Date) => {
    setValue('date', formatDateToISOString(date));
    setShowDateModal(false);
  };

  const handleSelectStartTime = ({ hoursString, minutesString }: TimePickerResult) => {
    setValue('start_time', `${hoursString}:${minutesString}:00`);
    setShowStartTimeModal(false);
    calculateAmountTime();
  };

  const handleSelectEndTime = ({ hoursString, minutesString }: TimePickerResult) => {
    setValue('end_time', `${hoursString}:${minutesString}:00`);
    setShowEndTimeModal(false);
    calculateAmountTime();
  };

  const handleSelectBreak = ({ hoursString, minutesString }: TimePickerResult) => {
    setValue('break', `${hoursString}:${minutesString}:00`);
    setShowBreakModal(false);
    calculateAmountTime();
  };

  const handleSelectTransfer = ({ hoursString, minutesString }: TimePickerResult) => {
    setValue('transfer', `${hoursString}:${minutesString}:00`);
    setShowTransferModal(false);
    calculateAmountTime();
  };

  const handleSelectProject = (project: Project) => {
    setValue('projectId', project.id as number);
    setValue('projectTitle', project.title);
    setValue('customerId', project.customer?.id as number);
    setValue('customerName', project.customer?.name as string);

    const newTasks: Task[] = project.tasks?.map(task => {
      return {
        id: task.id,
        description: task.description,
        hours: String(task.hours),
      };
    });
    setProjectTasks(newTasks);

    activitiesArray.fields.map((_, index) => {
      setValue(`activities.${index}.taskDescription`, undefined);
      setValue(`activities.${index}.task`, undefined);
    });

    setShowProjectModal(false);
  };

  const handleSelectTask = (task: Task, index: number) => {
    setValue(`activities.${index}.taskDescription`, task.description);
    setValue(`activities.${index}.task`, task.id);
    setTasksModal({ visible: false, index: -1 });
  };

  const calculateAmountTime = () => {
    const values = getValues();

    const startMinutes = formatTimeToMinutes(values.start_time);
    const endMinutes = formatTimeToMinutes(values.end_time);
    const breakMinutes = formatTimeToMinutes(values.break);
    const transferMinutes = formatTimeToMinutes(values.transfer);

    const resultMinutes = endMinutes - startMinutes - breakMinutes + transferMinutes;
    const result = `${resultMinutes < 0 ? '-' : ''}${formatMinutesToTime(resultMinutes)}:00`;

    setValue('amount_time', result);
  };

  const handleOnApprove = (approved: boolean) => onApprove?.(approved);

  return (
    <>
      <Container flexRow>
        <Controller
          control={control}
          defaultValue={formatDateToISOString(new Date())}
          name="date"
          render={({ field }) => {
            return (
              <ListItem
                description={formatISOStringToLocaleString(field.value)}
                disabled={viewMode}
                leftIcon="calendar"
                onPress={() => setShowDateModal(true)}
                style={styles.flex}
                title="Data"
              />
            );
          }}
        />
      </Container>
      <Spacer size="xsmall" />
      <Container flexRow gap="xsmall">
        <Container flexGrow>
          <Controller
            control={control}
            defaultValue=""
            name="start_time"
            render={({ field, fieldState }) => {
              return (
                <>
                  <ListItem
                    description={field.value || 'Selecione...'}
                    disabled={viewMode}
                    leftIcon="clock"
                    onPress={() => setShowStartTimeModal(true)}
                    title="Hora Início"
                  />
                  {!!fieldState.error && (
                    <HelperText type="error">{fieldState.error.message}</HelperText>
                  )}
                </>
              );
            }}
          />
        </Container>
        <Container flexGrow>
          <Controller
            control={control}
            name="end_time"
            render={({ field, fieldState }) => {
              return (
                <>
                  <ListItem
                    description={field.value || 'Selecione...'}
                    disabled={viewMode}
                    leftIcon="clock"
                    onPress={() => setShowEndTimeModal(true)}
                    title="Hora Fim"
                  />
                  {!!fieldState.error && (
                    <HelperText type="error">{fieldState.error.message}</HelperText>
                  )}
                </>
              );
            }}
          />
        </Container>
      </Container>

      <Spacer size="xsmall" />
      <Container flexRow gap="xsmall">
        <Controller
          control={control}
          defaultValue=""
          name="break"
          render={({ field }) => {
            return (
              <ListItem
                description={field.value || 'Selecione...'}
                disabled={viewMode}
                leftIcon="clock"
                onPress={() => setShowBreakModal(true)}
                style={styles.flex}
                title="Almoço"
              />
            );
          }}
        />
        <Controller
          control={control}
          defaultValue=""
          name="transfer"
          render={({ field }) => {
            return (
              <ListItem
                description={field.value || 'Selecione...'}
                disabled={viewMode}
                leftIcon="clock"
                onPress={() => setShowTransferModal(true)}
                style={styles.flex}
                title="Deslocamento"
              />
            );
          }}
        />
      </Container>
      <Input
        editable={false}
        name="amount_time"
        control={control}
        label="Quantidade de horas"
        leftIcon="clock"
      />
      <Pressable disabled={viewMode} onPress={() => setShowProjectModal(true)}>
        <Input
          name="projectTitle"
          control={control}
          editable={false}
          label="Projeto"
          leftIcon="briefcase"
          rightIcon="menu-down"
        />
      </Pressable>
      <Input
        name="customerName"
        control={control}
        editable={false}
        label="Cliente"
        leftIcon="account-tie"
      />
      <List.Section>
        <View style={styles.activitiesTitleContainer}>
          <Text variant="titleSmall">Atividades</Text>
          {!viewMode && (
            <IconButton
              containerColor={theme.colors.primary}
              icon="plus"
              iconColor={theme.colors.inversePrimary}
              style={styles.addIcon}
              onPress={() => activitiesArray.append({} as Activity)}
            />
          )}
        </View>
        {!!activitiesArray.fields &&
          activitiesArray.fields.map((_, index) => {
            const hasError = !!formState.errors?.activities?.[index];
            return (
              <List.Accordion
                key={index}
                left={({ color }) => <List.Icon color={color} icon="folder" />}
                title={`Atividade ${index + 1}${hasError ? ` (Preencha os campos)` : ''}`}
                titleStyle={{ color: hasError ? theme.colors.error : undefined }}>
                <Input
                  editable={!viewMode}
                  name={`activities.${index}.title`}
                  control={control}
                  label="Título"
                  leftIcon="pencil"
                />
                <Input
                  editable={!viewMode}
                  name={`activities.${index}.description`}
                  control={control}
                  label="Descrição"
                  leftIcon="pencil"
                  multiline
                />
                <Pressable
                  disabled={viewMode}
                  onPress={() => setTasksModal({ visible: true, index })}
                  style={styles.paddingLeftZero}>
                  <Input
                    name={`activities.${index}.taskDescription`}
                    control={control}
                    editable={false}
                    label="Tarefa"
                    leftIcon="briefcase"
                    rightIcon="menu-down"
                  />
                </Pressable>
                <Input
                  editable={!viewMode}
                  name={`activities.${index}.duration_time`}
                  control={control}
                  label="Duração"
                  leftIcon="clock"
                  mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                />
                <Spacer size="small" />
                {!viewMode && (
                  <Button mode="text" onPress={() => activitiesArray.remove(index)}>
                    Excluir Atividade {index + 1}
                  </Button>
                )}

                <SearchModal
                  hideSearchButton
                  items={projectTasks}
                  onDismiss={() => setTasksModal({ visible: false, index: -1 })}
                  onSelect={(task: Task) => handleSelectTask(task, index)}
                  visible={tasksModal.visible && tasksModal.index === index}
                />
              </List.Accordion>
            );
          })}
        {!!formState.errors?.activities && (
          <HelperText type="error">{formState.errors?.activities?.message}</HelperText>
        )}
      </List.Section>

      <Spacer size="medium" />
      {!viewMode && (
        <Button loading={loading} onPress={handleSubmit(onSubmit)}>
          Salvar
        </Button>
      )}
      {showApproveOptions && (
        <View>
          <Divider />
          <Spacer />
          <Text style={styles.approvalTitle} variant="titleLarge">
            Aprovação
          </Text>
          <Spacer size="small" />
          <Button loading={loading} onPress={() => handleOnApprove(true)}>
            Aprovar
          </Button>
          <Spacer size="small" />
          <Button loading={loading} mode="contained-tonal" onPress={() => handleOnApprove(false)}>
            Rejeitar
          </Button>
        </View>
      )}

      <DatePicker
        onClose={() => setShowDateModal(false)}
        onSelect={handleSelectDate}
        visible={showDateModal}
      />
      <TimePicker
        onClose={() => setShowStartTimeModal(false)}
        onSelect={handleSelectStartTime}
        visible={showStartTimeModal}
      />
      <TimePicker
        onClose={() => setShowEndTimeModal(false)}
        onSelect={handleSelectEndTime}
        visible={showEndTimeModal}
      />
      <TimePicker
        onClose={() => setShowBreakModal(false)}
        onSelect={handleSelectBreak}
        visible={showBreakModal}
      />
      <TimePicker
        onClose={() => setShowTransferModal(false)}
        onSelect={handleSelectTransfer}
        visible={showTransferModal}
      />
      <ProjectsModal
        onDismiss={() => setShowProjectModal(false)}
        onSelect={(project: Project | Project[]) => handleSelectProject(project as Project)}
        visible={showProjectModal}
      />
    </>
  );
}
