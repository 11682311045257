import React, { useState } from 'react';
import { Image, Platform } from 'react-native';
import { useForm } from 'react-hook-form';
import { Text, useTheme } from 'react-native-paper';
import { yupResolver } from '@hookform/resolvers/yup';
import { StackScreenProps } from '@react-navigation/stack';
import { getVersion } from 'react-native-device-info';
import * as yup from 'yup';

import { Button, Container, Input, Loading, Spacer } from '../../../components';
import { AuthStackParamList } from '../../../routes/auth';
import styles from './styles';
import { useAppDispatch } from '../../../store';
import { login } from '../../../store/actions/auth';
import Logo from '../../../assets/logo/logo.svg';

const validationSchema = yup.object().shape({
  identifier: yup
    .string()
    .required('Preencha o nome de usuário')
    .min(3, 'O nome de usuário deve ter no mínimo 3 dígitos'),
  password: yup
    .string()
    .required('Preencha a senha')
    .min(6, 'A senha deve ter no mínimo 6 dígitos'),
});

type FieldValues = {
  identifier: string;
  password: string;
};

type Props = StackScreenProps<AuthStackParamList, 'Login'>;

export default function Login({ navigation }: Props) {
  const { handleSubmit, control } = useForm<FieldValues>({
    resolver: yupResolver(validationSchema),
  });
  const theme = useTheme();
  const loginStyles = styles(theme);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(value => !value);
  };

  const onSubmit = async (data: FieldValues) => {
    setLoading(true);
    await dispatch(login(data));
    setLoading(false);
  };

  const goToRecoverAccount = () => {
    navigation.navigate('RecoverAccount');
  };

  const goToSignUp = () => {
    navigation.navigate('SignUp');
  };

  return (
    <Container flexGrow style={loginStyles.container}>
      <Container
        style={loginStyles.backgroundPurple}
        spacingHorizontal="medium"
        spacingVertical="medium"
        webSmall>
        {Platform.OS === 'web' && (
          <Image resizeMode="contain" source={Logo} style={loginStyles.logo} />
        )}
        {Platform.OS !== 'web' && <Logo width="100%" height={70} />}
        <Spacer />
        <Input name="identifier" control={control} label="Usuário" leftIcon="account" />
        <Input
          name="password"
          control={control}
          label="Senha"
          leftIcon="lock"
          rightIcon={showPassword ? 'eye-off' : 'eye'}
          rightIconOnPress={toggleShowPassword}
          secureTextEntry={!showPassword}
        />
        <Spacer size="small" />
        <Text
          onPress={goToRecoverAccount}
          style={[loginStyles.forgotPasswordText, loginStyles.linkText]}
          variant="bodyMedium">
          Esqueceu a senha?
        </Text>
        {loading && <Loading />}
        <Spacer size="medium" />
        {!loading && <Button onPress={handleSubmit(onSubmit)}>Entrar</Button>}
        <Spacer size="medium" />
        <Text variant="bodyMedium" style={loginStyles.signupText}>
          Você é novo por aqui?{' '}
          <Text onPress={goToSignUp} style={loginStyles.linkText}>
            Crie uma conta
          </Text>
        </Text>
        {Platform.OS !== 'web' && (
          <Text style={loginStyles.versionText} variant="bodySmall">
            Versão: {getVersion()}
          </Text>
        )}
      </Container>
    </Container>
  );
}
