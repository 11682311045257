import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import * as api from '../../services/project';
import { setError } from './error';
import { formatFilterObjectToString, normalizeErrorMessage, showToast } from '../../utils';
import { goBack } from '../../utils/navigation';
import { GetProjectsParams, PageOptions, ProjectWithRelations } from '../../types/project';
import { projectsOptionsSelector } from '../selectors/project';
import { RootState } from '..';

export const getProjects = createAsyncThunk(
  'project/getProjects',
  async (params: GetProjectsParams, { dispatch, getState }) => {
    try {
      const options = projectsOptionsSelector(getState() as RootState);

      const filter = options.filters.filter ?? '';
      const filters = formatFilterObjectToString({
        filters: {
          $or: [
            {
              title: {
                $containsi: filter,
              },
            },
            {
              description: {
                $containsi: filter,
              },
            },
          ],
        },
      });

      const { data } = await api.getProjects({ ...params, filters, orderBy: options.orderBy });
      return data;
    } catch (error) {
      dispatch(setError(normalizeErrorMessage(error)));
    }
  }
);

export const addProject = createAsyncThunk(
  'project/addProject',
  async (project: ProjectWithRelations, { dispatch }) => {
    try {
      const { data } = await api.addProject(project);
      showToast('Projeto incluído com sucesso');
      goBack();
      dispatch(getProjects({}));
      return data;
    } catch (error) {
      dispatch(setError(normalizeErrorMessage(error)));
    }
  }
);

export const editProject = createAsyncThunk(
  'project/editProject',
  async ({ id, project }: { id: number; project: ProjectWithRelations }, { dispatch }) => {
    try {
      const { data } = await api.editProject(id, project);
      showToast('Projeto editado com sucesso');
      goBack();
      dispatch(getProjects({}));
      return data;
    } catch (error) {
      dispatch(setError(normalizeErrorMessage(error)));
    }
  }
);

export const deleteProject = createAsyncThunk(
  'project/deleteProject',
  async (id: number, { dispatch }) => {
    try {
      const { data } = await api.deleteProject(id);
      showToast('Projeto excluído com sucesso');
      goBack();
      dispatch(getProjects({}));
      return data;
    } catch (error) {
      dispatch(setError(normalizeErrorMessage(error)));
    }
  }
);

export const setOptions = createAction<PageOptions>('project/setOptions');

export const setFilter = createAction<string>('project/setFilter');
