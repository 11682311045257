import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FlatList, ListRenderItem, RefreshControl } from 'react-native';
import { Appbar, Divider, IconButton, Searchbar } from 'react-native-paper';
import { StackScreenProps } from '@react-navigation/stack';
import { CompositeScreenProps } from '@react-navigation/native';
import { MaterialBottomTabScreenProps } from '@react-navigation/material-bottom-tabs';

import {
  AddRecordsButton,
  Container,
  DateQuickFilters,
  ListItem,
  Loading,
} from '../../../components';
import { useAppDispatch } from '../../../store';
import { UserStackParamList, UserTabParamList } from '../../../routes/user';
import {
  serviceOrderSelector,
  serviceOrdersListSelector,
  serviceOrdersOptionsSelector,
  serviceOrdersPaginationSelector,
} from '../../../store/selectors/service-order';
import { ServiceOrderData } from '../../../types/service-order';
import {
  getServiceOrders,
  setDateFilters,
  setFilter,
  setOptions,
} from '../../../store/actions/service-order';
import { formatDateToISOString, formatISOStringToLocaleString } from '../../../utils';
import styles from './styles';
import useDebounce from '../../../hooks/useDebounce';
import FiltersModal from '../components/filters-modal';
import { statusColors } from '../../../theme/colors';

type Props = CompositeScreenProps<
  MaterialBottomTabScreenProps<UserTabParamList, 'ServiceOrderHome'>,
  StackScreenProps<UserStackParamList>
>;

export default function ServiceOrderHome({ navigation }: Props) {
  const dispatch = useAppDispatch();
  const serviceOrdersList = useSelector(serviceOrdersListSelector);
  const options = useSelector(serviceOrdersOptionsSelector);
  const pagination = useSelector(serviceOrdersPaginationSelector);
  const loading = !!useSelector(serviceOrderSelector)?.loading;
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const customLabel =
    options?.filters?.dateId === 'custom'
      ? `${formatISOStringToLocaleString(
          options?.filters?.fromDate as string
        )} - ${formatISOStringToLocaleString(options?.filters?.toDate as string)}`
      : undefined;

  const loadServiceOrders = async (page = 1) => {
    dispatch(getServiceOrders({ page }));
  };

  const loadNextPage = () => {
    const nextPage = pagination.page + 1;
    if (nextPage <= pagination.pageCount) {
      loadServiceOrders(nextPage);
    }
  };

  const toggleShowSearchBar = () => {
    setShowSearchBar(oldValue => !oldValue);
  };

  const handleSetFilters = (data: { filters: { filter?: string }; orderBy: string }) => {
    dispatch(setOptions(data));
    setShowFiltersModal(false);
  };

  useDebounce(
    () => {
      loadServiceOrders(1);
    },
    500,
    [options.filters, options.orderBy]
  );

  const renderItem: ListRenderItem<ServiceOrderData> = ({ item }) => {
    const getIcon = () => {
      if (item.attributes.status === 'APPROVED') {
        return {
          color: statusColors.success,
          icon: 'check',
        };
      }

      if (item.attributes.status === 'REFUSED') {
        return {
          color: statusColors.danger,
          icon: 'close',
        };
      }

      return {
        color: statusColors.warning,
        icon: 'folder',
      };
    };

    const iconData = getIcon();
    return (
      <ListItem
        title={`OS: ${item.id} | ${formatISOStringToLocaleString(item.attributes.date)}`}
        description={`Cliente: ${item.attributes?.customer?.data?.attributes?.name || ''}`}
        leftIcon={iconData.icon}
        leftIconColor={iconData.color}
        onPress={() => navigation.navigate('ServiceOrderDetails', item)}
        rightIcon="chevron-right"
      />
    );
  };

  return (
    <>
      <Appbar.Header>
        <Appbar.Content title="Ordens de serviço" />
        <Appbar.Action icon="magnify" onPress={toggleShowSearchBar} />
      </Appbar.Header>
      <Container flexGrow>
        <DateQuickFilters
          customLabel={customLabel}
          onSelect={({ id, fromDate, toDate }) => {
            dispatch(
              setDateFilters({
                id,
                fromDate: fromDate ? formatDateToISOString(fromDate) : undefined,
                toDate: toDate ? formatDateToISOString(toDate) : undefined,
              })
            );
          }}
          selected={options?.filters?.dateId}
        />
        {showSearchBar && (
          <Container flexRow spacingHorizontal="small" spacingVertical="small">
            <Searchbar
              autoCapitalize="none"
              onChangeText={(text: string) => dispatch(setFilter(text))}
              placeholder="Pesquisar"
              style={styles.flex}
              value={options.filters.filter ?? ''}
            />
            <IconButton icon="filter-variant" size={25} onPress={() => setShowFiltersModal(true)} />
          </Container>
        )}
        <FlatList
          contentContainerStyle={styles.container}
          data={serviceOrdersList}
          ItemSeparatorComponent={Divider}
          keyExtractor={item => item.id.toString()}
          onEndReachedThreshold={0.2}
          onEndReached={loadNextPage}
          refreshControl={<RefreshControl refreshing={loading} onRefresh={loadServiceOrders} />}
          renderItem={renderItem}
        />
        {loading && <Loading />}
        <AddRecordsButton />
        <FiltersModal
          onConfirm={handleSetFilters}
          onDismiss={() => setShowFiltersModal(false)}
          visible={showFiltersModal}
        />
      </Container>
    </>
  );
}
