import React, { ReactNode } from 'react';
import { Button as PaperButton } from 'react-native-paper';

type Props = {
  onPress?: () => void;
  children: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  mode?: 'text' | 'outlined' | 'contained' | 'elevated' | 'contained-tonal';
};

export default function Button({
  onPress,
  children,
  disabled,
  loading,
  mode = 'contained',
}: Props) {
  function handleOnPress() {
    onPress && onPress();
  }

  return (
    <PaperButton
      disabled={disabled || loading}
      loading={loading}
      mode={mode}
      onPress={handleOnPress}>
      {children}
    </PaperButton>
  );
}
