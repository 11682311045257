import { Dimensions, Platform, StyleSheet } from 'react-native';

import { spacing } from '../../../theme/tokens';

const styles = StyleSheet.create({
  appliedFilterContainer: { flexWrap: 'wrap' },
  container: {
    maxHeight: Platform.OS === 'web' ? Dimensions.get('window').height - 100 : undefined,
    paddingHorizontal: spacing.medium,
  },
  flex: {
    flex: 1,
  },
});

export default styles;
