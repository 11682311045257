import { StyleSheet } from 'react-native';
import { spacing } from '../../theme/tokens';

const styles = StyleSheet.create({
  button: { marginTop: spacing.xsmall },
  menuItem: {
    flex: 1,
  },
  optionButton: {
    alignItems: 'center',
    flexDirection: 'row',
  },
});

export default styles;
