import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Button, Container, Input, Spacer } from '../../../components';
import styles from './styles';
import { useAppDispatch } from '../../../store';
import { changePassword } from '../../../store/actions/auth';
import { showToast } from '../../../utils';
import { StackScreenProps } from '@react-navigation/stack';
import { UserStackParamList } from '../../../routes/user';
import { ChangePasswordResponse } from '../../../types/auth';

const validationSchema = yup.object().shape({
  currentPassword: yup
    .string()
    .required('Preencha a senha atual')
    .min(6, 'A senha deve ter no mínimo 6 dígitos'),
  password: yup
    .string()
    .required('Preencha a nova senha')
    .min(6, 'A senha deve ter no mínimo 6 dígitos'),
  passwordConfirmation: yup
    .string()
    .required('Preencha a confirmação da senha')
    .min(6, 'A senha deve ter no mínimo 6 dígitos'),
});

type FieldValues = {
  currentPassword: string;
  password: string;
  passwordConfirmation: string;
};

type Props = StackScreenProps<UserStackParamList, 'ChangePassword'>;

export default function ChangePassword({ navigation }: Props) {
  const dispatch = useAppDispatch();
  const { handleSubmit, control } = useForm<FieldValues>({
    resolver: yupResolver(validationSchema),
  });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(value => !value);
  };

  const onSubmit = async (data: FieldValues) => {
    setLoading(true);
    const { payload } = await dispatch(changePassword(data));
    const response = payload as ChangePasswordResponse;
    if (response?.user) {
      showToast('Senha alterada com sucesso.');
      navigation.goBack();
    }
    setLoading(false);
  };

  return (
    <Container
      flexGrow
      safe
      spacingHorizontal="medium"
      style={styles.container}
      spacingVertical="medium"
      webSmall>
      <Input
        name="currentPassword"
        control={control}
        label="Senha Atual"
        leftIcon="lock"
        rightIcon={showPassword ? 'eye-off' : 'eye'}
        rightIconOnPress={toggleShowPassword}
        secureTextEntry={!showPassword}
      />
      <Input
        name="password"
        control={control}
        label="Nova Senha"
        leftIcon="lock"
        rightIcon={showPassword ? 'eye-off' : 'eye'}
        rightIconOnPress={toggleShowPassword}
        secureTextEntry={!showPassword}
      />
      <Input
        name="passwordConfirmation"
        control={control}
        label="Confirmação da Senha"
        leftIcon="lock"
        rightIcon={showPassword ? 'eye-off' : 'eye'}
        rightIconOnPress={toggleShowPassword}
        secureTextEntry={!showPassword}
      />
      <Spacer size="medium" />
      <Button loading={loading} onPress={handleSubmit(onSubmit)}>
        Confirmar
      </Button>
    </Container>
  );
}
