import React, { useEffect, useState } from 'react';

import SearchModal from '../search-modal';
import { Coordinator } from '../../types/coordinator';
import { getCoordinators } from '../../services/coordinator';
import { normalizeErrorMessage } from '../../utils';

type Props = {
  onDismiss: () => void;
  onSelect: (coordinator: Coordinator) => void;
  visible: boolean;
};

export default function CoordinatorsModal({ onDismiss, onSelect, visible }: Props) {
  const [loading, setLoading] = useState(false);
  const [coordinators, setCoordinators] = useState<Coordinator[]>([]);

  const loadCoordinators = async () => {
    setLoading(true);
    try {
      const { data } = await getCoordinators();
      const normalizedData = data.map(coordinator => {
        return { ...coordinator, description: coordinator.name };
      });
      setCoordinators(normalizedData);
    } catch (error) {
      setCoordinators([]);
      console.log(normalizeErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleOnSelect = (coordinator: Coordinator & { description?: string }) => {
    onSelect(coordinator);
  };

  useEffect(() => {
    loadCoordinators();
  }, []);

  return (
    <SearchModal
      hideSearchButton
      items={coordinators}
      loading={loading}
      onDismiss={onDismiss}
      onSelect={handleOnSelect}
      visible={visible}
    />
  );
}
