import React from 'react';
import { Dialog, Portal, Text } from 'react-native-paper';

import Button from '../button';
import styles from './styles';

type Props = {
  cancelText?: string;
  confirmText?: string;
  dismissable?: boolean;
  message: string;
  title?: string;
  visible: boolean;
  onConfirm?: () => void;
  onDismiss?: () => void;
};

export default function ConfirmDialog({
  cancelText,
  confirmText,
  dismissable = true,
  message,
  title,
  visible,
  onConfirm,
  onDismiss,
}: Props) {
  const handleOnDismiss = () => {
    dismissable && onDismiss && onDismiss();
  };

  const handleOnConfirm = () => {
    onConfirm && onConfirm();
  };

  return (
    <Portal>
      <Dialog
        dismissable={dismissable}
        onDismiss={handleOnDismiss}
        style={styles.dialog}
        visible={visible}>
        <Dialog.Title>{title || 'Confirmação'}</Dialog.Title>
        <Dialog.Content>
          <Text variant="bodyMedium">{message}</Text>
        </Dialog.Content>
        <Dialog.Actions>
          <Button mode="text" onPress={handleOnDismiss}>
            {cancelText || 'Não'}
          </Button>
          <Button onPress={handleOnConfirm}>{confirmText || 'Sim'}</Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
}
