import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { reducer as authReducer } from './reducers/auth';
import { reducer as consultantReducer } from './reducers/consultant';
import { reducer as coordinatorReducer } from './reducers/coordinator';
import { reducer as customerReducer } from './reducers/customer';
import { reducer as errorReducer } from './reducers/error';
import { reducer as invoiceReducer } from './reducers/invoice';
import { reducer as projectReducer } from './reducers/project';
import { reducer as serviceOrderReducer } from './reducers/service-order';
import { useDispatch } from 'react-redux';

const persistConfig = {
  storage: AsyncStorage,
};

const reducer = combineReducers({
  auth: persistReducer({ ...persistConfig, key: 'auth' }, authReducer),
  consultant: consultantReducer,
  coordinator: coordinatorReducer,
  customer: customerReducer,
  error: errorReducer,
  invoice: invoiceReducer,
  project: projectReducer,
  serviceOrder: serviceOrderReducer,
});

export const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = useDispatch<AppDispatch>;
