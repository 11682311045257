import api from './api';

import { Customer, GetCustomersParams, GetCustomersResponse } from '../types/customer';
import { getCompanyId } from '../utils/auth';

export const getCustomers = async (params: GetCustomersParams = {}) => {
  const { filters, orderBy = 'name', page = 1 } = params;
  let url = `/api/customers?pagination[page]=${page}`;
  if (orderBy) {
    url += `&sort=${orderBy}`;
  }
  if (filters) {
    url += `&${filters}`;
  }

  return api.get<GetCustomersResponse>(url);
};

export const addCustomer = async (customer: Customer) => {
  const companyId = getCompanyId();
  return api.post('/api/customers', { data: { ...customer, company: [companyId] } });
};

export const editCustomer = async (id: number, customer: Customer) => {
  return api.put(`/api/customers/${id}`, { data: customer });
};

export const deleteCustomer = async (id: number) => {
  return api.delete(`/api/customers/${id}`);
};
