import React, { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Chip, HelperText, IconButton, Text, useTheme } from 'react-native-paper';
import * as yup from 'yup';

import { Button, Input, ProjectsModal, Spacer } from '../../../../components';
import { AddConsultantParams } from '../../../../types/consultant';
import { View } from 'react-native';
import styles from './styles';
import { Project } from '../../../../types/project';

const validationSchema = yup.object().shape({
  name: yup.string().required('Preencha o nome').min(3, 'O nome deve ter no mínimo 3 dígitos'),
  email: yup.string().required('Preencha o e-mail').email('Digite um endereço de e-mail válido'),
  username: yup
    .string()
    .required('Preencha o nome de usuário')
    .min(3, 'O nome de usuário deve ter no mínimo 3 dígitos'),
  projects: yup.array().min(1, 'Informe no mínimo um projeto'),
});

type Props = {
  editMode?: boolean;
  initialValues?: Partial<AddConsultantParams>;
  loading?: boolean;
  onSubmit?: (data: AddConsultantParams) => void;
};
export default function UserForm({ editMode = false, initialValues, loading, onSubmit }: Props) {
  const theme = useTheme();
  const [showProjectModal, setShowProjectModal] = useState(false);
  const { control, formState, handleSubmit, setValue } = useForm<AddConsultantParams>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });
  const projectsArray = useFieldArray({ name: 'projects', control, keyName: 'customId' });

  const handleSelectProject = (projects: Project[]) => {
    const newItems = [...projectsArray.fields] as Project[];

    projects.forEach(project => {
      const itemFound = newItems.find(item => item.id === project.id);
      if (!itemFound) {
        newItems.push(project);
      }
    });

    setValue('projects', newItems);
    setShowProjectModal(false);
  };

  return (
    <>
      <Input name="name" control={control} editable={!editMode} label="Nome" leftIcon="account" />
      <Input name="email" control={control} editable={!editMode} label="E-mail" leftIcon="email" />
      <Input
        name="username"
        control={control}
        editable={!editMode}
        label="Usuário"
        leftIcon="account"
      />
      <Spacer size="medium" />
      <View style={styles.projectsTitleContainer}>
        <Text variant="titleSmall">Projetos</Text>
        <IconButton
          containerColor={theme.colors.primary}
          icon="plus"
          iconColor={theme.colors.inversePrimary}
          style={styles.addIcon}
          onPress={() => setShowProjectModal(true)}
        />
      </View>
      <View style={styles.projectsContainer}>
        {!!projectsArray.fields &&
          projectsArray.fields.map((item, index) => {
            return (
              <Chip key={item.customId} onClose={() => projectsArray.remove(index)}>
                {item.title}
              </Chip>
            );
          })}
      </View>

      {!!formState.errors?.projects && (
        <HelperText type="error">{formState.errors?.projects?.message}</HelperText>
      )}

      <Spacer size="medium" />
      {!!onSubmit && (
        <Button loading={loading} onPress={handleSubmit(onSubmit)}>
          Salvar
        </Button>
      )}
      <ProjectsModal
        multiSelect
        onDismiss={() => setShowProjectModal(false)}
        onSelect={(projects: Project | Project[]) => handleSelectProject(projects as Project[])}
        visible={showProjectModal}
      />
    </>
  );
}
