import React, { useState } from 'react';
import { Pressable, View } from 'react-native';
import { HelperText, IconButton, List, Text, useTheme } from 'react-native-paper';
import { createNumberMask } from 'react-native-mask-input';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  Button,
  Container,
  CoordinatorsModal,
  CustomersModal,
  DatePicker,
  DropDown,
  Input,
  ListItem,
  Spacer,
} from '../../../../components';
import { formatDateToISOString, formatISOStringToLocaleString } from '../../../../utils';
import styles from './styles';
import { CustomerData } from '../../../../types/customer';
import { Task } from '../../../../types/project';
import { Coordinator } from '../../../../types/coordinator';

const currencyMask = createNumberMask({
  delimiter: '.',
  separator: ',',
  precision: 2,
});

const validationSchema = yup.object().shape({
  title: yup
    .string()
    .required('Preencha o nome do projeto')
    .min(3, 'O nome do projeto deve ter no mínimo 3 dígitos'),
  description: yup
    .string()
    .required('Preencha a descrição do projeto')
    .min(3, 'A descrição do projeto deve ter no mínimo 3 dígitos'),
  coordinatorName: yup.string().required('Selecione o coordenador'),
  customerName: yup.string().required('Selecione o cliente'),
  hours_traded: yup.string().required('Preencha a quantidade de horas negociadas'),
  hour_value: yup.string().required('Preencha o valor da hora'),
  tasks: yup
    .array()
    .of(
      yup.object().shape({
        description: yup.string().required('Preencha a descrição da tarefa'),
        hours: yup.string().required('Preencha a quantidade de horas da tarefa'),
      })
    )
    .min(1, 'Informe no mínimo uma tarefa'),
});

export type FormData = {
  description: string;
  cancellation_date: string;
  createdAt: string;
  end: string;
  hours_traded: number | string;
  hour_value: number | string;
  premises: string;
  publishedAt: string;
  reason_cancellation: string;
  restrictions: string;
  start: string;
  status: 'aberto' | 'cancelado' | 'fechado' | 'negociando' | 'distrato';
  tasks: Task[];
  title: string;
  updatedAt: string;
  coordinatorId: number;
  coordinatorName: string;
  customerId: number;
  customerName: string;
};

type Props = {
  addMode?: boolean;
  initialValues?: Partial<FormData>;
  loading?: boolean;
  onSubmit: (data: FormData) => void;
  viewMode?: boolean;
};

export default function ProjectForm({
  addMode,
  initialValues,
  loading,
  onSubmit,
  viewMode,
}: Props) {
  const theme = useTheme();
  const { control, formState, handleSubmit, setValue } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });
  const tasksArray = useFieldArray({ name: 'tasks', control: control });
  const [showCoordinatorModal, setShowCoordinatorModal] = useState(false);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [showStartDateModal, setShowStartDateModal] = useState(false);
  const [showEndDateModal, setShowEndDateModal] = useState(false);
  const [showStatusOptions, setShowStatusOptions] = useState(false);

  const handleSelectCoordinator = (coordinator: Coordinator) => {
    setValue('coordinatorId', coordinator.id);
    setValue('coordinatorName', coordinator.name);
    setShowCoordinatorModal(false);
  };

  const handleSelectCustomer = (customer: CustomerData) => {
    setValue('customerId', customer.id);
    setValue('customerName', customer.attributes.name);
    setShowCustomerModal(false);
  };

  const handleSelectStartDate = (date: Date) => {
    setValue('start', formatDateToISOString(date));
    setShowStartDateModal(false);
  };

  const handleSelectEndDate = (date: Date) => {
    setValue('end', formatDateToISOString(date));
    setShowEndDateModal(false);
  };

  return (
    <>
      <Container flexRow>
        <Controller
          control={control}
          defaultValue={formatDateToISOString(new Date())}
          name="start"
          render={({ field }) => {
            return (
              <ListItem
                description={formatISOStringToLocaleString(field.value)}
                disabled={viewMode}
                leftIcon="calendar"
                onPress={() => setShowStartDateModal(true)}
                style={styles.flex}
                title="Data de Início"
              />
            );
          }}
        />

        <Controller
          control={control}
          defaultValue={formatDateToISOString(new Date())}
          name="end"
          render={({ field }) => {
            return (
              <ListItem
                description={formatISOStringToLocaleString(field.value)}
                disabled={viewMode}
                leftIcon="calendar"
                onPress={() => setShowEndDateModal(true)}
                style={styles.flex}
                title="Data de Fim"
              />
            );
          }}
        />
      </Container>
      <Pressable disabled={viewMode} onPress={() => setShowCustomerModal(true)}>
        <Input
          name="customerName"
          control={control}
          editable={false}
          label="Cliente"
          leftIcon="account-tie"
          rightIcon="menu-down"
        />
      </Pressable>
      <Pressable disabled={viewMode} onPress={() => setShowCoordinatorModal(true)}>
        <Input
          name="coordinatorName"
          control={control}
          editable={false}
          label="Coordenador"
          leftIcon="account-tie"
          rightIcon="menu-down"
        />
      </Pressable>
      <Input editable={!viewMode} name="title" control={control} label="Nome" leftIcon="account" />
      <Input
        editable={!viewMode}
        name="description"
        control={control}
        label="Descrição"
        leftIcon="account"
        multiline
      />
      <Input
        editable={!viewMode}
        name="premises"
        control={control}
        label="Premissas"
        leftIcon="account"
        multiline
      />
      <Input
        editable={!viewMode}
        name="restrictions"
        control={control}
        label="Restrições"
        leftIcon="account"
        multiline
      />

      {!addMode && (
        <Controller
          control={control}
          defaultValue="aberto"
          name="status"
          render={({ field }) => {
            return (
              <DropDown
                disabled={viewMode}
                label="Status"
                leftIcon="account"
                visible={showStatusOptions}
                showDropDown={() => setShowStatusOptions(true)}
                onDismiss={() => setShowStatusOptions(false)}
                value={field.value}
                setValue={value => setValue('status', value)}
                list={[
                  {
                    label: 'Aberto',
                    value: 'aberto',
                  },
                  {
                    label: 'Cancelado',
                    value: 'cancelado',
                  },
                  {
                    label: 'Fechado',
                    value: 'fechado',
                  },
                  {
                    label: 'Negociando',
                    value: 'negociando',
                  },
                  {
                    label: 'Distrato',
                    value: 'distrato',
                  },
                ]}
              />
            );
          }}
        />
      )}

      <Input
        editable={!viewMode}
        name="hours_traded"
        control={control}
        label="Horas Negociadas"
        leftIcon="clock"
        mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      />
      <Input
        editable={!viewMode}
        name="hour_value"
        control={control}
        label="Valor da hora"
        leftIcon="currency-usd"
        mask={currencyMask}
      />
      <List.Section>
        <View style={styles.tasksTitleContainer}>
          <Text variant="titleSmall">Tarefas</Text>
          {!viewMode && (
            <IconButton
              containerColor={theme.colors.primary}
              icon="plus"
              iconColor={theme.colors.inversePrimary}
              style={styles.addIcon}
              onPress={() => tasksArray.append({} as Task)}
            />
          )}
        </View>
        {!!tasksArray.fields &&
          tasksArray.fields.map((_, index) => {
            const hasError = !!formState.errors?.tasks?.[index];
            return (
              <List.Accordion
                key={index}
                left={({ color }) => <List.Icon color={color} icon="folder" />}
                title={`Tarefa ${index + 1}${hasError ? ` (Preencha os campos)` : ''}`}
                titleStyle={{ color: hasError ? theme.colors.error : undefined }}>
                <Input
                  editable={!viewMode}
                  name={`tasks.${index}.description`}
                  control={control}
                  label="Descrição"
                  leftIcon="pencil"
                  multiline
                />
                <Input
                  editable={!viewMode}
                  name={`tasks.${index}.hours`}
                  control={control}
                  label="Horas"
                  leftIcon="clock"
                  mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                />
                <Spacer size="small" />
                {!viewMode && (
                  <Button mode="text" onPress={() => tasksArray.remove(index)}>
                    Excluir Tarefa {index + 1}
                  </Button>
                )}
              </List.Accordion>
            );
          })}
      </List.Section>
      {!!formState.errors?.tasks && (
        <HelperText type="error">{formState.errors?.tasks?.message}</HelperText>
      )}
      <Spacer size="medium" />
      {!viewMode && (
        <Button loading={loading} onPress={handleSubmit(onSubmit)}>
          Salvar
        </Button>
      )}

      <CoordinatorsModal
        onDismiss={() => setShowCoordinatorModal(false)}
        onSelect={handleSelectCoordinator}
        visible={showCoordinatorModal}
      />

      <CustomersModal
        onDismiss={() => setShowCustomerModal(false)}
        onSelect={handleSelectCustomer}
        visible={showCustomerModal}
      />

      <DatePicker
        onClose={() => setShowStartDateModal(false)}
        onSelect={handleSelectStartDate}
        visible={showStartDateModal}
      />
      <DatePicker
        onClose={() => setShowEndDateModal(false)}
        onSelect={handleSelectEndDate}
        visible={showEndDateModal}
      />
    </>
  );
}
