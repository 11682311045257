type ToastRef = { show: (message: string) => void };

class ToastUtil {
  ref: ToastRef | null = null;

  setRef(ref: ToastRef | null) {
    this.ref = ref;
  }

  show(message: string) {
    this.ref?.show(message);
  }
}

const toastUtil = new ToastUtil();

export default toastUtil;
