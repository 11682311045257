import { StyleSheet } from 'react-native';
import { spacing } from '../../../../theme/tokens';

const styles = StyleSheet.create({
  addIcon: {
    margin: 0,
  },
  deleteIcon: {
    margin: 0,
  },
  flex: { flex: 1 },
  tasksTitleContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: spacing.small,
  },
});

export default styles;
