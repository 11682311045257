import api from './api';

import { AddInvoiceParams, GetInvoicesParams, GetInvoicesResponse } from '../types/invoice';

export const addInvoice = async (params: AddInvoiceParams) => {
  // FIXME: tipagem
  return api.post<any>('/api/invoices', {
    data: params,
  });
};

export const getInvoices = async (params: GetInvoicesParams = {}) => {
  const { filters, orderBy = 'date:desc', page = 1 } = params;
  let url = `/api/invoices?populate=*&pagination[page]=${page}`;
  if (orderBy) {
    url += `&sort=${orderBy}`;
  }
  if (filters) {
    url += `&${filters}`;
  }

  return api.get<GetInvoicesResponse>(url);
};

export const deleteInvoice = async (id: number) => {
  return api.delete(`/api/invoices/${id}`);
};

export const downloadInvoice = async (id: number) => {
  return api.post<Blob>(
    '/api/invoices/download',
    {
      data: {
        invoice: id,
      },
    },
    { responseType: 'blob' }
  );
};
