import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import * as api from '../../services/service-order';
import { setError } from './error';
import { formatFilterObjectToString, normalizeErrorMessage, showToast } from '../../utils';
import { goBack } from '../../utils/navigation';
import {
  GetServiceOrdersParams,
  PageOptions,
  ServiceOrderWithRelations,
} from '../../types/service-order';
import { serviceOrdersOptionsSelector } from '../selectors/service-order';
import { RootState } from '..';
import { QuickDateItem } from '../../components/date-quick-filters';

export const getServiceOrders = createAsyncThunk(
  'service-order/getServiceOrders',
  async (params: GetServiceOrdersParams, { dispatch, getState }) => {
    try {
      const options = serviceOrdersOptionsSelector(getState() as RootState);

      const filter = options.filters.filter ?? '';
      const filters: { [key: string]: any } = {
        $or: [
          {
            id: {
              $containsi: filter,
            },
          },
          {
            date: {
              $containsi: filter.split('/').reverse().join('-'),
            },
          },
          {
            customer: {
              name: {
                $containsi: filter,
              },
            },
          },
        ],
      };

      if (options?.filters?.dateId) {
        filters.date = {
          $gte: options?.filters?.fromDate,
          $lte: options?.filters?.toDate,
        };
      }

      const { data } = await api.getServiceOrders({
        ...params,
        filters: formatFilterObjectToString({ filters }),
        orderBy: options.orderBy,
      });
      return data;
    } catch (error) {
      dispatch(setError(normalizeErrorMessage(error)));
    }
  }
);

export const getServiceOrder = createAsyncThunk(
  'service-order/getServiceOrder',
  async (id: number, { dispatch }) => {
    try {
      const { data } = await api.getServiceOrder(id);
      return data.data;
    } catch (error) {
      dispatch(setError(normalizeErrorMessage(error)));
    }
  }
);

export const addServiceOrder = createAsyncThunk(
  'service-order/addServiceOrder',
  async (serviceOrder: ServiceOrderWithRelations, { dispatch }) => {
    try {
      const { data } = await api.addServiceOrder(serviceOrder);
      showToast(`Ordem de serviço ${data.id} incluída com sucesso`);
      goBack();
      dispatch(getServiceOrders({}));
      return data;
    } catch (error) {
      dispatch(setError(normalizeErrorMessage(error)));
    }
  }
);

export const editServiceOrder = createAsyncThunk(
  'service-order/editServiceOrder',
  async (
    { id, serviceOrder }: { id: number; serviceOrder: ServiceOrderWithRelations },
    { dispatch }
  ) => {
    try {
      const { data } = await api.editServiceOrder(id, serviceOrder);
      showToast(`Ordem de serviço ${id} editada com sucesso`);
      goBack();
      dispatch(getServiceOrders({}));
      return data;
    } catch (error) {
      dispatch(setError(normalizeErrorMessage(error)));
    }
  }
);

export const deleteServiceOrder = createAsyncThunk(
  'service-order/deleteServiceOrder',
  async (id: number, { dispatch }) => {
    try {
      const { data } = await api.deleteServiceOrder(id);
      showToast('Ordem de serviço excluída com sucesso');
      goBack();
      dispatch(getServiceOrders({}));
      return data;
    } catch (error) {
      dispatch(setError(normalizeErrorMessage(error)));
    }
  }
);

export const sendByEmail = createAsyncThunk(
  'service-order/deleteServiceOrder',
  async ({ id, email }: { id: number; email: string }, { dispatch }) => {
    try {
      await api.sendByEmail(id, email);
      showToast('E-mail enviado com sucesso');
      goBack();
    } catch (error) {
      dispatch(setError(normalizeErrorMessage(error)));
    }
  }
);

export const setOptions = createAction<PageOptions>('service-order/setOptions');

export const setFilter = createAction<string>('service-order/setFilter');

export const setDateFilters = createAction<{
  id?: QuickDateItem['id'];
  fromDate?: string;
  toDate?: string;
}>('service-order/setDateFilters');
