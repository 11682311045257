import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IconButton } from 'react-native-paper';
import { StackScreenProps } from '@react-navigation/stack';

import { ConfirmDialog, Container } from '../../../components';
import { Customer } from '../../../types/customer';
import { useAppDispatch } from '../../../store';
import { deleteCustomer, editCustomer } from '../../../store/actions/customer';
import { UserStackParamList } from '../../../routes/user';
import CustomerForm from '../components/customer-form';
import { isMasterSelector } from '../../../store/selectors/auth';
import styles from './styles';

type Props = StackScreenProps<UserStackParamList, 'CustomerDetails'>;

export default function CustomerDetails({ route, navigation }: Props) {
  const dispatch = useAppDispatch();
  const isMaster = useSelector(isMasterSelector);
  const { params } = route;
  const [loading, setLoading] = useState(false);
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);

  const handleDeleteCustomer = () => {
    setShowConfirmAlert(false);
    dispatch(deleteCustomer(params.id));
  };

  const onSubmit = async (data: Customer) => {
    setLoading(true);
    await dispatch(editCustomer({ id: params.id, customer: data }));
    setLoading(false);
  };

  useEffect(() => {
    if (params?.id && isMaster) {
      navigation.setOptions({
        headerRight: () => (
          <IconButton icon="delete" size={25} onPress={() => setShowConfirmAlert(true)} />
        ),
      });
    }
  }, []);

  return (
    <Container
      flexGrow
      spacingHorizontal="medium"
      spacingVertical="medium"
      style={styles.container}>
      <CustomerForm
        initialValues={{
          name: params.attributes.name,
          cellphone: params.attributes.cellphone,
          email: params.attributes.email,
        }}
        loading={loading}
        onSubmit={onSubmit}
        viewMode={!isMaster}
      />
      <ConfirmDialog
        message="Você deseja excluir o cliente?"
        onConfirm={handleDeleteCustomer}
        onDismiss={() => setShowConfirmAlert(false)}
        visible={showConfirmAlert}
      />
    </Container>
  );
}
