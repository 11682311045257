import { createReducer } from '@reduxjs/toolkit';

import { Consultant } from '../../types/consultant';
import { getConsultants } from '../actions/consultant';

const INITIAL_STATE = {
  loading: false,
  list: [] as Consultant[],
};

export const reducer = createReducer(INITIAL_STATE, builder => {
  builder
    .addCase(getConsultants.pending, state => {
      state.loading = true;
    })
    .addCase(getConsultants.rejected, state => {
      state.loading = false;
    })
    .addCase(getConsultants.fulfilled, (state, { payload }) => {
      if (payload) {
        state.list = payload;
      }
      state.loading = false;
    });
});
