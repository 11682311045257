import React, { ReactNode } from 'react';
import { View, StyleProp, ViewStyle } from 'react-native';
import { useTheme } from 'react-native-paper';
import { SafeAreaView } from 'react-native-safe-area-context';

import styles from './styles';

type Props = {
  children: ReactNode;
  flexGrow?: boolean;
  flexRow?: boolean;
  gap?: 'xxsmall' | 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
  safe?: boolean;
  spacingHorizontal?: 'xxsmall' | 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
  spacingVertical?: 'xxsmall' | 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
  style?: StyleProp<ViewStyle>;
  webSmall?: boolean;
};

export default function Container({
  children,
  flexGrow = false,
  flexRow = false,
  gap,
  safe,
  spacingHorizontal,
  spacingVertical,
  style,
  webSmall,
}: Props) {
  const theme = useTheme();
  const containerStyles = styles({
    flexGrow,
    flexRow,
    gap,
    spacingHorizontal,
    spacingVertical,
    theme,
    webSmall,
  });

  if (safe)
    return <SafeAreaView style={[containerStyles.container, style]}>{children}</SafeAreaView>;

  return <View style={[containerStyles.container, style]}>{children}</View>;
}
