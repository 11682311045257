import { AppRegistry } from 'react-native';
import App from './app';

const appName = 'ServiceOrder'; // TODO: import do app.json fora do src da erro, mas se trocar o app não funciona
if (module.hot) {
  module.hot.accept();
}

AppRegistry.registerComponent(appName, () => App);
AppRegistry.runApplication(appName, {
  initialProps: {},
  rootTag: document.getElementById('root'),
});
